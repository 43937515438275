// Grid mixin
// --------------------------------------------------

// - easy way to use all breakpoint in one mixin
.make-column(
	@large,
	@medium,
	@small,
	@xsmall,
	@tiny:12,
	@column-width: @grid-gutter-width,
) {
	.make-xs-column(@tiny;@column-width);
	.make-xsm-column(@xsmall;@column-width);
	.make-sm-column(@small;@column-width);
	.make-md-column(@medium;@column-width);
	.make-lg-column(@large;@column-width);
}

.make-column-offset(
	@large,
	@medium,
	@small,
	@xsmall,
	@tiny:0,
) {
	.make-xs-column-offset(@tiny);
	.make-xsm-column-offset(@xsmall);
	.make-sm-column-offset(@small);
	.make-md-column-offset(@medium);
	.make-lg-column-offset(@large);
}

// Generate the small columns
.make-xsm-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (min-width: @screen-phone) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}
.make-xsm-column-offset(@columns) {
	@media (min-width: @screen-phone) {
		margin-left: percentage((@columns / @grid-columns));
	}
}
.make-xsm-column-push(@columns) {
	@media (min-width: @screen-phone) {
		left: percentage((@columns / @grid-columns));
	}
}
.make-xsm-column-pull(@columns) {
	@media (min-width: @screen-phone) {
		right: percentage((@columns / @grid-columns));
	}
}

//.make-flex-column(
// 	@large,
// 	@medium,
// 	@small,
// 	@xsmall,
// 	@tiny:12,
// 	@column-width: @grid-gutter-width,
// ) {
// 	// .make-flex-col(
// 	// 	@size: @tiny;
// 	// 	@columns: @column-width;
// 	// );
// 	@media (min-width: @screen-phone) {
// 		.make-flex-col(
// 			@size: @xsmall;
// 			@columns: @column-width;
// 		);
// 	}
// 	@media (min-width: @screen-tablet) {
// 		.make-flex-col(
// 			@size: @small;
// 			@columns: @column-width;
// 		);
// 	}

// 	@media (min-width: @screen-desktop) {
// 		.make-flex-col(
// 			@size: @medium;
// 			@columns: @column-width;
// 		);

// 	}

// 	@media (min-width: @screen-widescreen) {
// 		.make-flex-col(
// 			@size: @large;
// 			@columns: @column-width;
// 		);
// 	}
// }

/*responsive-utilitie add*/
.hidden-tiny {
	@media (max-width: @screen-tinyphone-max) {
		.responsive-invisibility();
	}
}

//## Mixin reorder div
.reorder-div(
	@media-query: @grid-float-breakpoint-max;
	@div-margin: @line-height-computed/2;
	@reorder-style: flex;
) {
	@media (max-width: @media-query) {
		& when (@reorder-style = grid) {
			.rotate(180deg);
			direction: rtl;
			.mb(@div-margin);
			> div {
				.rotate(-180deg);
				direction: ltr;
			}
		}
		& when (@reorder-style = flex) {
			> div {
				&:last-child {
					order: -1;
				}
			}
		}
	}
}


//## bootstrap grid using
// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @grid-gutter-width) {
	&:extend(.clearfix all);
	margin-right: auto;
	margin-left: auto;
	padding-left: floor((@gutter / 2));
	padding-right: ceil((@gutter / 2));

	.clearfix;
}

// Creates a wrapper for a series of columns
.make-row(@gutter: @grid-gutter-width) {
	.ml(ceil((@gutter / -2)));
	.mr(floor((@gutter / -2)));
	&:extend(.clearfix all);
}

.make-flex-row(@gutter: @grid-gutter-width) {
	.flex-display();
	.flex-wrap(wrap);
	.ml(ceil((@gutter / -2)));
	.mr(floor((@gutter / -2)));
}

.make-flex-col-ready(@gutter: @grid-gutter-width) {
	position: relative;
	// Prevent columns from becoming too narrow when at smaller grid tiers by
	// always setting `width: 100%;`. This works because we use `flex` values
	// later on to override this initial width.
	width: 100%;
	min-height: 1px; // Prevent collapsing
	.ph((@gutter / 2));
}
.make-flex-col(
	@size;
	@columns: @grid-columns;
) {
	.flex(0 0 percentage(@size / @columns));
	// Add a `max-width` to ensure content within each column does not blow out
	// the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
	// do not appear to require this.
	max-width: percentage(@size / @columns);
}


// Generate the extra small columns
.make-xs-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	float: left;
	width: percentage((@columns / @grid-columns));
	min-height: 1px;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);
}
.make-xs-column-offset(@columns) {
	margin-left: percentage((@columns / @grid-columns));
}
.make-xs-column-push(@columns) {
	left: percentage((@columns / @grid-columns));
}
.make-xs-column-pull(@columns) {
	right: percentage((@columns / @grid-columns));
}

// Generate the small columns
.make-sm-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (min-width: @screen-tablet) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}
.make-sm-column-offset(@columns) {
	@media (min-width: @screen-tablet) {
		margin-left: percentage((@columns / @grid-columns));
	}
}
.make-sm-column-push(@columns) {
	@media (min-width: @screen-tablet) {
		left: percentage((@columns / @grid-columns));
	}
}
.make-sm-column-pull(@columns) {
	@media (min-width: @screen-tablet) {
		right: percentage((@columns / @grid-columns));
	}
}

// Generate the medium columns
.make-md-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left: (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (min-width: @screen-desktop) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}
.make-md-column-offset(@columns) {
	@media (min-width: @screen-desktop) {
		margin-left: percentage((@columns / @grid-columns));
	}
}
.make-md-column-push(@columns) {
	@media (min-width: @screen-desktop) {
		left: percentage((@columns / @grid-columns));
	}
}
.make-md-column-pull(@columns) {
	@media (min-width: @screen-desktop) {
		right: percentage((@columns / @grid-columns));
	}
}

// Generate the large columns
.make-lg-column(@columns; @gutter: @grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  (@gutter / 2);
	padding-right: (@gutter / 2);

	@media (min-width: @screen-widescreen) {
		float: left;
		width: percentage((@columns / @grid-columns));
	}
}
.make-lg-column-offset(@columns) {
	@media (min-width: @screen-widescreen) {
		margin-left: percentage((@columns / @grid-columns));
	}
}
.make-lg-column-push(@columns) {
	@media (min-width: @screen-widescreen) {
		left: percentage((@columns / @grid-columns));
	}
}

.make-lg-column-pull(@columns) {
	@media (min-width: @screen-widescreen) {
		right: percentage((@columns / @grid-columns));
	}
}

//## namespace grid section structure
// whens : page-container, home,
#grid-sections {
	.make-column-grid(
		@page-section;
		@column-width: @grid-gutter-width;
		@grid-style: grid;
	) {
		& when not (@is-prototype) {
			& when (@grid-style = grid) or (@grid-style = grid-page-content) or (@grid-style = grid-full) {
				.make-row(@column-width);
			}

			& when (@grid-style = flex) {
				.make-flex-row(@gutter: @column-width);
			}
			& when (@page-section = flexcontacts) {
				.justify-content(left);
				> .box-popular-column {
					.make-flex-col-ready(@gutter: @column-width);
					.pv(@grid-gutter-width/2);
				}
				@media (min-width: @screen-tablet) {
					&.items-2 {
						> .box-popular-column {
							.make-flex-col(
								@size: 6;
								@columns: @grid-columns;
							);
						}
					}
					&.items-3,
					&.has-flex {
						> .box-popular-column {
							.make-flex-col(
								@size: 6;
								@columns: @grid-columns,
							);
						}
					}
				}

				@media (min-width: @screen-desktop) {
					&.items-3,
					&.has-flex {
						> .box-popular-column {
							.make-flex-col(
								@size: 4;
								@columns: @grid-columns,
							);
						}
					}
				}
			}


			& when(@page-section = flexicons) {
				.list-unstyled();
				.justify-content(left);
				> li {
					.make-flex-col-ready(@gutter: @column-width);
					.pv(@grid-gutter-width/2);
				}
				&:not(.has-flex) {
					> li {
						@media (min-width: @screen-phone) {
							.flex-basis(0); //flex-basis: 0;
							.flex-grow(1);
						}
					}
					// &.icons {
					// 	> li {
					// 		@media (min-width: @screen-phone) and (max-width: @screen-phone-max) {
					// 			.make-flex-col(
					// 				@size: 6,
					// 				@columns: @grid-columns
					// 			);
					// 		}
					// 		@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max) {
					// 			.make-flex-col(
					// 				@size: 4,
					// 				@columns: @grid-columns
					// 			);
					// 		}
					// 		@media (min-width: @screen-desktop) {
					// 			.make-flex-col(
					// 				@size: 2,
					// 				@columns: @grid-columns
					// 			);
					// 		}
					// 	}
					// }

				}
				&.has-flex {
					&.icons {
						> li  {
							// @media (min-width: @screen-phone) and (max-width: @screen-phone-max) {
							// 	.make-flex-col(
							// 		@size: 6,
							// 		@columns: @grid-columns
							// 	);
							// }
							// @media (min-width: @screen-tablet) and (max-width: @screen-tablet-max) {
							// 	.make-flex-col(
							// 		@size: 4,
							// 		@columns: @grid-columns
							// 	);
							// }
							// @media (min-width: @screen-desktop) {
							// 	.make-flex-col(
							// 		@size: 3,
							// 		@columns: @grid-columns
							// 	);
							// }
							@media (min-width: @screen-phone) and (max-width: @screen-phone-max) {
								.make-flex-col(
									@size: 6,
									@columns: @grid-columns
								);
							}
							@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max) {
								.make-flex-col(
									@size: 4,
									@columns: @grid-columns
								);
							}
							@media (min-width: @screen-desktop) and (max-width: @screen-desktop-max) {
								.make-flex-col(
									@size: 3,
									@columns: @grid-columns
								);
							}
							@media (min-width: @screen-widescreen) {
								.make-flex-col(
									@size: 2,
									@columns: @grid-columns
								);
							}
						}
					}
				}
			}
			& when(@page-section = home-flexicons) {
				.list-unstyled();
				.justify-content(left);
				> li {
					.make-flex-col-ready(@gutter: @column-width);
					.pv(@grid-gutter-width/2);
				}
				&:not(.has-flex) {
					> li {
						@media (min-width: @screen-phone) {
							.flex-basis(0); //flex-basis: 0;
							.flex-grow(1);
						}
					}
					// &.icons {
					// 	> li {
					// 		@media (min-width: @screen-phone) and (max-width: @screen-phone-max) {
					// 			.make-flex-col(
					// 				@size: 6,
					// 				@columns: @grid-columns
					// 			);
					// 		}
					// 		@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max) {
					// 			.make-flex-col(
					// 				@size: 4,
					// 				@columns: @grid-columns
					// 			);
					// 		}
					// 		@media (min-width: @screen-desktop) {
					// 			.make-flex-col(
					// 				@size: 2,
					// 				@columns: @grid-columns
					// 			);
					// 		}
					// 	}
					// }

				}
				&.has-flex {
					&.icons {
						> li  {

							@media (max-width: @screen-tinyphone-max) {
								.make-flex-col(
									@size: 12,
									@columns: @grid-columns
								);
							}
							@media (min-width: @screen-phone) and (max-width: @screen-phone-max) {
								.make-flex-col(
									@size: 6,
									@columns: @grid-columns
								);
							}
							@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max) {
								.make-flex-col(
									@size: 4,
									@columns: @grid-columns
								);
							}
							@media (min-width: @screen-desktop) {
								.make-flex-col(
									@size: 6,
									@columns: @grid-columns
								);
							}
						}
					}
				}
			}

			& when(@page-section = subitems) {
				> li {
					.pv((@column-width/2));
					.make-column(4, 4, 6, 12, 12, @column-width);
				}
			}

			& when(@page-section = partial-masonry) {
				> li {
					.pv((@column-width/2));
				}
				& when (@grid-style = grid) {
					> li {
						.make-column(4, 4, 6, 6, 12, @column-width);
					}
				}
				& when (@grid-style = flex) {
					> li {
						.make-flex-col-ready(@gutter: @column-width);

						@media (max-width: @screen-tinyphone-max) {
							.make-flex-col(
								@size: 12,
								@columns: @grid-columns
							);
						}

						@media (min-width: @screen-phone) and (max-width: @screen-phone-max) {
							.make-flex-col(
								@size: 6,
								@columns: @grid-columns
							);
						}

						@media (min-width: @screen-tablet) {
							.make-flex-col(
								@size: 4,
								@columns: @grid-columns
							);
						}

					}
				}
			}

			& when(@page-section = box-wrapper) {
				> .box-column {
					.make-md-column(6;@column-width);
				}
			}

			& when(@page-section = list-grid) {
				.list-unstyled();
				& when (@grid-style = flex) {
					> li {
						.make-flex-col-ready(@gutter: @column-width);
						@media (min-width: @screen-phone) and (max-width: @grid-float-breakpoint-max) {
							.make-flex-col(
								@size: 6;
								@columns: @grid-columns;
							);
						}
					}
					&:not(.has-flex) {
						> li {
							@media (min-width: @grid-float-breakpoint) {
								.flex-basis(0); //flex-basis: 0;
								.flex-grow(1);
							}
						}
					}
					&.has-flex {
						> li {
							@media (min-width: @grid-float-breakpoint) and (max-width: @screen-tablet-max) {
								.make-flex-col(
									@size: 4;
									@columns: @grid-columns;
								);
							}
							@media (min-width: @screen-desktop) {
								.make-flex-col(
									@size: 3;
									@columns: @grid-columns;
								);
							}
						}
					}
				}
				& when (@grid-style = grid) {
					> li {
						.make-column(6, 6, 6, 12, 12, @column-width);
						// background-color: blue;
						@media (min-width:@screen-tablet) {
							.pb(@padding-base-vertical);
						}
					}
				}
				& when (@grid-style = grid-full) {
					> li {
						.make-column(4, 4, 6, 6, 12, @column-width);
						@media (min-width:@screen-tablet) {
							.pb(@padding-base-vertical);
						}
					}
				}
				& when (@grid-style = grid-page-content) {
					> li {
						.make-column(4, 4, 6, 6, 12, @column-width);
					}
				}
			}

			& when(@page-section = box-grid) {
				&.column {
					> .title,
					.box-title {
						.make-column(3, 4, 12, 4, 12, @column-width);
						+ div {
							.make-column(9, 8, 12, 8, 12, @column-width);
							~ .box-block {
								.make-column-offset(3, 4, 0, 4, 0);
							}
						}
					}
				}
				&:not(.column) {
					> .title,
					.box-title {
						.make-column(3, 3, 4, 4, 12, @column-width);
						+ div {
							.make-column(9, 9, 8, 8, 12, @column-width);
							~ .box-block {
								.make-column-offset(3, 3, 4, 4, 0);
							}
						}
					}
				}
				@media (max-width: @screen-tinyphone-max) {
					> .title,
					.box-title {
						.mb(@heading-base-margin/2) !important;
					}
				}
			}

			& when(@page-section = person-grid) {
				> .partial-content {
					.make-column(9, 9, 9, 8, 12, @column-width);
					@media (max-width: @screen-phone-max) {
						.mb(@line-height-computed);
					}
				}
				> .person-photo {
					.make-column(3, 3, 3, 4, 12, @column-width);
				}
				.reorder-div(
					@media-query: @screen-tinyphone-max;
					@div-margin: @column-width;
				);
			}

			& when(@page-section = person-contact) {
				> div {
					.pv((@column-width/2));
					.make-flex-col-ready(@gutter: @column-width);
					// @media (min-width: @screen-desktop) {
					// 	.flex-basis(0); //flex-basis: 0;
					// 	.flex-grow(1);
					// }

					@media (min-width: @screen-desktop) {
						.make-flex-col(
							@size: 3;
							@columns: @grid-columns,
						);
					}
					@media (min-width: @screen-phone) and (max-width: @screen-tablet-max) {
						.make-flex-col(
							@size: 6;
							@columns: @grid-columns,
						);
					}
				}
			}

			& when(@page-section = page-column) {
				& when (@grid-style = flex) {
					> div {
						.pv(@column-width/2);
						.make-flex-col-ready(@gutter: @column-width);
					}
				}
				> .page-content {
					& when (@grid-style = grid) {
						.make-column(8, 8, 12, 12, 12, @column-width);
					}
					& when (@grid-style = flex) {
						@media (min-width: @screen-desktop) {
							.make-flex-col(
								@size: 8;
								@columns: @grid-columns,
							);
						}
					}
					@media (max-width: @screen-tablet-max) {
						.mb(@line-height-computed);
					}
				}
				> .side-bar {
					& when (@grid-style = grid) {
						.make-column(4, 4, 12, 12, 12, @column-width);
					}
					& when (@grid-style = flex) {
						@media (min-width: @screen-desktop) {
							.make-flex-col(
								@size: 4;
								@columns: @grid-columns,
							);
						}
					}
				}
			}

			& when(@page-section = reorder-atoz) {
				.reorder-div(
					@media-query: @screen-atoz-max;
					@div-margin: @column-width;
				);

				@media (max-width: @screen-atoz-max) {
					.box-filter {
						.hide;
					}
				}
			}

			& when(@page-section = spot-columns) {
				&.item-1{
					> .spot-column {
						.make-column(12, 12, 12, 12, 12, @column-width);
					}
				}
				// &.item-2 {
				&:not(.item-1) {
					.spot-column {
						.make-column(6, 6, 12, 12, 12, @column-width);
					}
				}
			}

			& when (@page-section = spot-home-one) {
				.spot-photo {
					.make-column(2,3,4,5,12,  @column-width);
				}
				.spot-content {
					.make-column(10,9,8,7,12,  @column-width);
				}
			}
			& when (@page-section = spot-home-more) {
				.spot-photo {
					.make-column(5,5,5,5,12,  @column-width);
				}
				.spot-content {
					.make-column(7,7,7,7,12,  @column-width);
				}
			}

			& when(@page-section = contact-content) {
				// > .contact-info-container,
				// > .contact-hours-container {			}

				> .contact-info-container {
					.make-column(6, 6, 12, 12, 12, @column-width);
					&:not(:last-child) {
						.mb(0) !important;
					}
					@media (max-width: @screen-phone-max) {
						&:not(:last-child) {
							.mb(@spacing-vertical) !important;
						}
					}
				}
				> .contact-hours-container {
					// .make-column-offset(4, 4, 0, 0, 0);
					.make-column(6, 6, 12, 12, 12, @column-width);
				}
				> .contact-info-extra {
					.make-xs-column(12, @column-width);
				}
			}

			& when(@page-section = hours-info) {
				.hours-today,
				.hours-tomorrow {
					.make-column(6, 6, 12, 12, 12, @column-width);
				}
				.hours-today {
					> .hours-block {
						.pr(@box-padding-horizontal - 1);
						.pb(@box-padding-vertical - 1);
						border-right: 1px solid transparent;
						border-bottom: 1px solid @white;
						@media (min-width: @screen-desktop) {
							border-color: 1px solid @white;
							border-right-color: @white;
							border-bottom-color: transparent;
						}
					}

				}
			}

			& when(@page-section = contact-info) {
				dl.contact-info {
					.make-column(6, 12, 6, 12, 12, @column-width);
				}
			}

			& when(@page-section = contact-column) {
				@media (max-width: @screen-phone-max) {
					.contact-content,
					.streetmap-content,
					.google-map-content, {
						&:not(:last-child) {
							.mb(@line-height-computed);
						}
					}
				}
				> .contact-content {
					.make-column(8, 8, 7, 12, 12, @column-width);
				}
				> .google-map-content,
				> .streetmap-content {
					.make-column(4, 4, 5, 12, 12, @column-width);
					@media (min-width: @screen-phone) {
						.pull-right;
					}
				}
			}

			& when (@page-section = media-content) {
				> div {
					.pv(@grid-gutter-width/2);
					& when (@grid-style = flex) {
							.make-flex-col-ready(@gutter: @column-width);
					}
				}
				& when (@grid-style = flex) {
					> *:not(:only-child) {
						&.media-content {
							@media (min-width: @screen-phone) {
								order:2;
								.make-flex-col(
									@size: 3;
									@columns: @grid-columns;
								);
							};
						}
						&.info {
							@media (min-width: @screen-phone) {
								order: 1;
								.make-flex-col(
									@size: 9;
									@columns: @grid-columns;
								);
							}
						}
					}
				}
			}

			& when (@page-section = partial-overview) {
				> div {
					.make-flex-col-ready(@gutter: @column-width);
				}
				> *:not(:only-child) {
					&.partial-photo {
						@media (min-width: @screen-phone) {
							order:1;
							.make-flex-col(
								@size: 4;
								@columns: @grid-columns;
							);
						};
						@media (max-width: @screen-tinyphone-max) {
							.mb(@spacing-vertical);
						}
					}
					&.partial-content {
						@media (min-width: @screen-phone) {
							order: 2;
							.make-flex-col(
								@size: 8;
								@columns: @grid-columns;
							);
						}
					}

				}
			}

			& when(@page-section = media-spotlight) {
				> li {
					.pv((@column-width/2));
					.make-column(4,4,4,6,12, @column-width);
				}
			}

			& when(@page-section = media-overview) {

				> li {
					& when (@grid-style = flex) {
						.make-flex-col-ready(@gutter: @column-width);
						@media (max-width: @screen-mobile) {
							.make-flex-col(
								@size: 12,
								@columns: @grid-columns
							);
						}
						@media (min-width: @screen-tinyphone) and (max-width: @screen-tinyphone-max) {
							.make-flex-col(
								@size: 6,
								@columns: @grid-columns
							);
						}
						@media (min-width: @screen-phone) and (max-width: @screen-phone-max) {
							.make-flex-col(
								@size: 4,
								@columns: @grid-columns
							);
						}
						@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max) {
							.make-flex-col(
								@size: 4,
								@columns: @grid-columns
							);
						}
						@media (min-width: @screen-desktop) and (max-width: @screen-desktop-max) {
							.make-flex-col(
								@size: 3,
								@columns: @grid-columns
							);
						}
						@media (min-width: @screen-widescreen) {
							.make-flex-col(
								@size: 3,
								@columns: @grid-columns
							);
						}
					}
					& when (@grid-style = grid) {
						.make-column(3,3,3,6,12, @column-width);
					}
					.pv((@column-width/2));
				}
			}

			& when(@page-section = media-overview-index) {
				> li {
					& when (@grid-style = flex) {
						.make-flex-col-ready(@gutter: @column-width);
						@media (min-width: @screen-phone) and (max-width: @screen-tablet-max) {
							.make-flex-col(
								@size: 6,
								@columns: @grid-columns
							);
						}
						@media (min-width: @screen-desktop) {
							.make-flex-col(
								@size: 4,
								@columns: @grid-columns
							);
						}
					}
					.pv((@column-width/2));
				}
			}

			& when(@page-section = media-box) {
				> li {
					&:not(:only-child) {
						.make-column(6,6,6,6,12, @column-width);
					}
					&:only-child {
						.make-xs-column(12, @column-width);
					}
					.pv((@column-width/2));
				}
			}

			& when(@page-section = tab-overview) {
				.ph(@grid-gutter-width/2);
				.mh(-@grid-gutter-width/2);
				@media (max-width: @screen-tablet-max) {
					> .tab-side,
					> .tab-content {
						.make-flex-col(
							@size: 12,
							@columns: @grid-columns
						);
					}
				}
				@media (min-width: @screen-desktop) {
					> .tab-content {
						.make-flex-col(
							@size: 8,
							@columns: @grid-columns
						);
					}
					> .tab-side {
						@media (min-width: @screen-desktop) {
							.make-flex-col(
								@size: 4,
								@columns: @grid-columns
							);
						}
					}
				}
			}

			& when(@page-section = footer) {
				.footer-left,
				.footer-right {
					.make-column(2,2,3,12,12, @column-width);
				}
				.footer-mid {
					.make-column(8,8,6,12,12, @column-width);
				}
				.footer-left {
					position: static;
					.contact-footer-container {
						position: absolute;
						left: (@grid-gutter-width/2);
						bottom: 4px;
					}
				}
				.footer-mid {
					.text-center;
					.mb(@spacing);
				}
				.footer-right {
					.text-right;
					.pl(100) //-- width copyright gemeente
				}
				@media (min-width: @screen-tablet) {
					.footer-left {
						.text-left;
						.contact-footer-container {
							position: relative;
							left: auto;
							bottom: auto;
						}
					}
					.footer-mid {
						.mb(0);
					}
					.footer-right {
						.pl(0);
						position: static; //voor alignment social nav
						.footer-social {
							.align-block(
								@position: absolute;
								@position-v: center;
								@position-h: right;
							);
							right: (@grid-gutter-width/2);
						}
					}
				}
			}

			& when(@page-section = birthday-overview-home) {
				> li {
					.make-column(3,3,4,6,12, @column-width);
					// .pv((@column-width/2));
					.mb(@padding-large-vertical);
				}
			}

			& when(@page-section = birthday-overview) {
				> li {
					.make-column(4,4,4,6,12, @column-width);
					// .pv((@column-width/2));
					.mb(@padding-large-vertical);
				}
			}

			& when(@page-section = footer-inner) {
				.footer-left {
					.make-column(10,9,12,8,12,  @column-width);
					.mb(@padding-base-vertical);
					@media (min-width: @screen-desktop) {
						.mb(0);
					}
				}
				.footer-right {
					.make-column(2,3,12,4,12,  @column-width);
				}
			}

			& when(@page-section = form-row) {
				> .group-col-2 {
					.make-sm-column(2);
				}

				> .group-col-3 {
					.make-sm-column(3);
				}

				> .group-col-4 {
					.make-sm-column(4);
				}
				> .group-col-5 {
					.make-sm-column(5);
				}

				> .group-col-6 {
					.make-sm-column(6);
				}
				> .group-col-7 {
					.make-sm-column(7);
				}

				> .group-col-8 {
					.make-sm-column(8);
				}

				> .group-col-9 {
					.make-sm-column(9);
				}

				> .group-col-10 {
					.make-sm-column(10);
				}
				> .group-col-11 {
					.make-sm-column(11);
				}
				> .group-col-12 {
					.make-sm-column(12);
				}


				.form-group {
					&:last-child {
						.mb(@form-group-margin-bottom);
					}
				}
			}

			& when(@page-section = group-crab) or (@page-section = form-row) {
				.group-street {
					.make-sm-column(6);
				}

				.group-houseno,
				.group-bus,
				.group-postalcode {
					.make-sm-column(3);
				}

				.group-city {
					.make-sm-column(9);
				}
			}

			& when(@page-section = error) {
				> div {
					&:first-child {
						.make-column-offset(4, 3, 0, 0, 0);
						.make-column(4, 4, 4, 12, 12, @column-width);

					}
					&:last-child {
						display: none;
						// .make-column(2, 2, 2, 12, 12, @column-width);

					}
				}
			}

		}
	}
}
