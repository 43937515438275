// - Border-radius
.border-radius(...) { 
	@process: ~`(function(e){e=e||"0";var t=/\d/gi,r=/(?:\s|^)(\.?\d+\.?\d*)(?![^(]*\)|\w|%|\.)/gi;return/^[^, ]*,/.test(e)&&(e=e.replace(/(?:,)(?![^(]*\))/g,"")),t.test(e)&&(e=e.replace(r,function(e){return 0==e&&e||e+"px"})),e})((function(){var e="@{arguments}";return e=e.replace(/^\[|\]$/g,"")})())`;
	-webkit-border-radius: @process;
	-moz-border-radius: @process;
	border-radius: @process;
}

// Single side border-radius

.border-top-radius(@radius) {
	border-top-right-radius: @radius;
	border-top-left-radius: @radius;
}
.border-right-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-top-right-radius: @radius;
}
.border-bottom-radius(@radius) {
	border-bottom-right-radius: @radius;
	border-bottom-left-radius: @radius;
}
.border-left-radius(@radius) {
	border-bottom-left-radius: @radius;
	border-top-left-radius: @radius;
}
