// Structure mixins

//## border
.border-variant( 
	@border-variant-color: @border-color; 
	@border-variant-width: 1px) 
{
	border: @border-variant-width solid @border-variant-color;
}
.border { border: 1px solid @border-color; }
.border-bottom-none { border-bottom: 0; }
.border-top { border-top: 1px solid @border-color; }
.border-bottom { border-bottom: 1px solid @border-color; }
.border-dotted { border-style: dotted; }


.no-border { border: 0; }
.no-padding { .p(0); }
.no-margin { .m(0); }
.no-border-radius { .border-radius(0); }
.no-background { background: transparent;}

.br-50 { .border-radius(50%); }
.br-100, .circle { .border-radius(100%); };

.full-width { width: 100%; }

//background mixins
.bg (
	@bgurl;
	@bgcolor: transparent; 
	@bgrepeat: no-repeat; 
	@bgposh: left; 
	@bgposv: top; 
) {
	background: @bgcolor url('@{bgurl}') @bgrepeat @bgposh @bgposv;
}

.bg-color(@bgcolor) {
	background-color: @bgcolor;
}

//padding mixin
.p(@padding: @padding-base-vertical) {
	.check-unit(
		@property: padding;
		@unit: @padding;
	);
}

.pt(@paddingtop: @padding-base-vertical) {
	.check-unit(
		@property: padding-top;
		@unit: @paddingtop;
	);
}

.pb(@paddingbottom: @padding-base-vertical) {
	.check-unit(
		@property: padding-bottom;
		@unit: @paddingbottom;
	);
}

.pl(@paddingleft: @padding-base-horizontal) {
	.check-unit(
		@property: padding-left;
		@unit: @paddingleft;
	);
}

.pr(@paddingright: @padding-base-horizontal) {
	.check-unit(
		@property: padding-right;
		@unit: @paddingright;
	);
}

.ph(@paddinghorizontal: @padding-base-horizontal) {
	.check-unit(
		@property: padding-left;
		@unit: @paddinghorizontal;
	);
	.check-unit(
		@property: padding-right;
		@unit: @paddinghorizontal;
	);
}

.pv(@paddingvertical: @padding-base-vertical) {
	.check-unit(
		@property: padding-top;
		@unit: @paddingvertical;
	);
	.check-unit(
		@property: padding-bottom;
		@unit: @paddingvertical;
	);
}

//margin mixin
.m(@margin: @padding-base-vertical) {
	.check-unit(
		@property: margin;
		@unit: @margin;
	);
}
.mt(@margintop: @padding-base-vertical) {
	.check-unit(
		@property: margin-top;
		@unit: @margintop;
	);
}

.mb(@marginbottom: @padding-base-vertical) {
	.check-unit(
		@property: margin-bottom;
		@unit: @marginbottom;
	);
}

.ml(@marginleft: @padding-base-horizontal) {
	.check-unit(
		@property: margin-left;
		@unit: @marginleft;
	);
}

.mr(@marginright: @padding-base-horizontal) {
	.check-unit(
		@property: margin-right;
		@unit: @marginright;
	);
}

.mh(@marginhorizontal: @padding-base-horizontal) {
	.check-unit(
		@property: margin-left;
		@unit: @marginhorizontal;
	);
	.check-unit(
		@property: margin-right;
		@unit: @marginhorizontal;
	);
}

.mv(@marginvertical: @padding-base-vertical) {
	.check-unit(
		@property: margin-top;
		@unit: @marginvertical;
	);
	.check-unit(
		@property: margin-bottom;
		@unit: @marginvertical;
	);
}

//absolute mixin
.t(@absolutevertical: 0) {
	.check-unit(
		@property: top;
		@unit: @absolutevertical;
	);
}
.b(@absolutevertical: 0) {
	.check-unit(
		@property: bottom;
		@unit: @absolutevertical;
	);
}
.l(@absolutehorizontal: 0) {
	.check-unit(
		@property: left;
		@unit: @absolutehorizontal;
	);
}
.r(@absolutehorizontal: 0) {
	.check-unit(
		@property: right;
		@unit: @absolutehorizontal;
	);
}
