/****************************
* COMPONENTS :: TYPOGRAPHY *
****************************/

// Font declaratie
.text-title {
	font-family: @headings-font-family;
	font-weight: @headings-font-weight;
}

.text-light {
	font-family: @font-family-light;
	font-weight: @font-weight-light;
}

.text-normal {
	font-family: @font-family-base;
	font-weight: @font-weight-base;
}

.text-base {
	.text-normal; //.text-normal
}

.text-semibold {
	font-family: @font-family-semibold;
	font-weight: @font-weight-semibold;
}
.text-bold {
	font-family: @font-family-bold;
	font-weight: @font-weight-bold;
}

//custom type global zie type less hieronder overrule
.text-custom-light {
	.font-alt(@font-custom-light);
	font-weight: @font-weight-custom-base;
}

.text-custom {
	.font-alt(@font-custom-base);
	font-weight: @font-weight-custom-base;
}
.text-custom-semibold {
	.font-alt(@font-custom-semibold);
	font-weight: @font-weight-custom-semibold;
}
.text-custom-bold {
	.font-alt(@font-custom-bold);
	font-weight: @font-weight-custom-bold;
}

// Alignment
.text-left 			{ text-align: left; }
.text-right 		{ text-align: right; }
.text-center 		{ text-align: center; }
.text-justify 		{ text-align: justify; }
.text-nowrap 		{ white-space: nowrap; }

// Transformation
.text-lowercase 	{ text-transform: lowercase; }
.text-uppercase 	{ text-transform: uppercase; }
.text-capitalize 	{ text-transform: capitalize; }
.text-nocase 		{ text-transform: none; }

.text-italic 		{font-style: italic;};

// Decoration
.text-underline 	{ text-decoration: underline; }
.text-none 			{ text-decoration: none; }

// Contextual colors
.text-muted {
	color: @text-muted;
}
.text-primary {
	.text-emphasis-variant(@brand-primary);
}
.text-success {
	.text-emphasis-variant(@state-success-text);
}
.text-info {
	.text-emphasis-variant(@state-info-text);
}
.text-warning {
	.text-emphasis-variant(@state-warning-text);
}
.text-danger {
	.text-emphasis-variant(@state-danger-text);
}
