// LCP mixins
// --------------------------------------------------

//##namespace mixins
#bg-zone {
	.bg-style(
		@style: @color1-lighter;
		@type: global;
		@bg-gutter-width: 100%;
		@bg-padding-horizontal: @box-padding-horizontal;
		@bg-padding-vertical: (@grid-gutter-width/2);
	) {
		// &:extend(.container-wrapper all);
		.pv(@bg-padding-vertical);
		& when (@type = global) {
			.ph(@bg-padding-horizontal);
		}
		& when (@type = none) {
			.ph(0);
		}

		.bg-color(@style);

		& when (@type = full) {
			position: relative;
			.ph(0);
			&:before,
			&:after {
				content: "";
				background-color: @style;
				position: absolute;
				.t(0);
				height: 100%;
				z-index: -1;
				.check-unit(
					@property: width;
					@unit: @bg-gutter-width;
				);
				& when (ispercentage(@bg-gutter-width)) {
					width: 100vw;
				}
			}
			&:before {
				.r(100%);
			}
			&:after {
				.l(100%);
			}
		}
		& when (@type = left) {
			position: relative;
			&:before {
				content: "";
				background-color: @style;
				position: absolute;
				.t(0);
				.r(100%);
				.check-unit(
					@property: width;
					@unit: @bg-gutter-width;
				);
				& when (ispercentage(@bg-gutter-width)) {
					width: 100vw;
				}
				height: 100%;
			}
		}
		& when (@type = right) {
			position: relative;
			&:after {
				content: "";
				background-color: @style;
				position: absolute;
				.t(0);
				.l(100%);
				.check-unit(
					@property: width;
					@unit: @bg-gutter-width;
				);
				& when (ispercentage(@bg-gutter-width)) {
					width: 100vw;
				}
				height: 100%;
			}

		}

		// & when (@style = @color1-lighter) { }
	}
}

#bg-deco {
	.bg-style(
		@color: @brand-primary;
		@type: triangle-patern;
		@style: diagonal;
	) {
		& when (@type = triangle-patern) {
			@triangle-width: 70%;
			@triangle-height: 600px;
			@triangle-angle: -45deg;
			&:after,
			&:before {
				content: " ";
				position: absolute;
				bottom: 0;
				left: 0;
				width: @triangle-width;
				height: @triangle-height;
			}
			&:before {
				z-index: -2;
				& when (@style = diagonal) {
					#gradient > .patern-diagonal-stripes(
						@start-color: @color;
						@end-color: @body-bg;
						@angle: @triangle-angle;
					);
				}
				& when (@style = vertical) {
					#gradient > .patern-vertical-stripes(
						@start-color: @color;
						@end-color: @body-bg;
					);
				}
				& when (@style = horizontal) {
					#gradient > .patern-horizontal-stripes(
						@start-color: @color;
						@end-color: @body-bg;
					);
				}
				& when (@style = radial) {
					#gradient > .patern-radial-stripes(
						@start-color: @color;
						@end-color: @body-bg;
					);
				}
			}
			&:after {
				z-index: -1;
				#gradient > .triangle(
					@start-color: @body-bg;
					@end-color: transparent;
					@pos: left bottom;
				);
			}
		}
	}
}

#fullscreen-bg {
	.bg-size(
		@size-type: cover;
		@bg-position-left: center;
		@bg-position-top: center;
	) {
		background-position: @bg-position-left @bg-position-top;
		background-repeat: no-repeat;
		height: 100%;
		& when (@size-type = cover) {
			.background-size(cover);
		}
		& when (@size-type = fixed) {
			@media (max-width: @grid-float-breakpoint-max) {
				background-position: center center;
				.background-size(cover);
			}
			&.is-touch {
				background-position: center center;
				.background-size(cover);
			}
			&:not(.is-touch) {
				@media (min-width: @grid-float-breakpoint) {
					background-position: @bg-position-left @bg-position-top;
					background-attachment: fixed;
					.background-size(cover);
				}
			}
		}
		/*
		@media (max-width: @screen-desktop-max) {
			.background-size(cover);
		}

		@media (min-width: @screen-widescreen) {
			.background-size(auto 100%);
		}
		*/
	}
}

.makeRandomnumber(@min: 0, @max: @min+1, @int: 0) {
	.checkInt() {
		@getNum: `Math.random() * (@{max} - @{min} + @{int})`;
		@base: unit(`@{int} == 1 ? Math.floor(@{getNum}) : @{getNum}`);
	}
	.checkInt();
	@randNum: @base + @min;
}

//## Mixin css seperator
.css-seperator(
	@seperator-color: @hr-border;
	@seperator-style: fullwidth;
	@seperator-position: top;
	@seperator-spacing: @line-height-computed;
	@seperator-border-style: dotted;
) {
	position: relative;
	&:before,
	&:after {
		& when (@seperator-style = fullwidth) {
			position: absolute;
			width: 100%;
			left: 0;
		}
		& when (@seperator-style = mini) {
			width: 15%;
			.align-horizontal(
				@position: absolute;
				@position-h: center;
			);
		}
	}
	& when (@seperator-position = top) {
		.pt((@seperator-spacing * 2) + 1);
		&:before {
			content: "";
			top: (@seperator-spacing);
			border-top: 1px @seperator-border-style @seperator-color;
		}
	}
	& when (@seperator-position = bottom) {
		.pb((@seperator-spacing * 2) + 1);
		&:after {
			content: "";

			bottom: (@seperator-spacing);
			border-bottom: 1px @seperator-border-style @seperator-color;

		}
	}
	& when (@seperator-position = both) {
		.pt((@seperator-spacing * 2) + 1);
		.pb((@seperator-spacing * 2) + 1);
		&:before,
		&:after {
			position: absolute;
			left: 0;
			content: "";
		}
		&:before {
			top: 0;
			border-top: 1px @seperator-border-style @seperator-color;
		}
		&:after {
			bottom: 0;
			border-bottom: 1px @seperator-border-style @seperator-color;
		}
	}
}

.css-seperator-variant(
	@seperator-color-variant;
	@seperator-position: top;
) {
	& when (@seperator-position = top) {
		&:before {
			border-top-color: @seperator-color-variant;
		}
	}
	& when (@seperator-position = bottom) {
		&:after {
			border-bottom-color: @seperator-color-variant;
		}
	}
}

//## MIXIN SHADOW
.shadow-style (
	@x: 0px,
	@y: 0px,
	@blur: 0px,
	@color: @black,
	@alpha: 0
) {
	@shadow: @x @y @blur fade(@color, @alpha);
	.box-shadow(@shadow);
}

// MIXIN dropdown shadow
.dropdown-shadow(
	@shadow-style: base;
	@shadow-color: @black;
	@enable-transition: false;
) {
	& when (@enable-transition) {
		.shadow-transition();
	}
	& when (@shadow-style = base) {
		.shadow-style(
			@x: 0px,
			@y: 6px,
			@blur: 12px,
			@color: @shadow-color,
			@alpha: 17%
		);
	}
	& when not (@shadow-style = base) {
		& when (@shadow-style = bottom1) {
			@shadow: 0 0 0 0 fade(@shadow-color, 0), 0 2px 10px 0 fade(@shadow-color, 10%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = bottom2) {
			@shadow: 0 2px 5px 0 fade(@shadow-color, 11%), 0 2px 10px 0 fade(@shadow-color, 12%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = bottom3) {
			@shadow: 0 8px 17px 0 fade(@shadow-color, 20%), 0 6px 20px 0 fade(@shadow-color, 20%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = bottom4) {
			@shadow: 0 12px 15px 0 fade(@shadow-color, 24%), 0 17px 50px 0 fade(@shadow-color, 20%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = bottom5) {
			@shadow: 0 16px 28px 0 fade(@shadow-color, 22%), 0 25px 55px 0 fade(@shadow-color, 21%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = bottom6) {
			@shadow: 0 27px 24px 0 fade(@shadow-color, 20%), 0 40px 77px 0 fade(@shadow-color, 22%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = top1) {
			@shadow: 0 0 0 0 fade(@shadow-color, 0), 0 -2px 10px 0 fade(@shadow-color, 10%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = top2) {
			@shadow: 0 -2px 5px 0 fade(@shadow-color, 11%), 0 -2px 10px 0 fade(@shadow-color, 12%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = top3) {
			@shadow: 0 -8px 17px 0 fade(@shadow-color, 20%), 0 -6px 20px 0 fade(@shadow-color, 20%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = top4) {
			@shadow: 0 -12px 15px 0 fade(@shadow-color, 24%), 0 -17px 50px 0 fade(@shadow-color, 20%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = top5) {
			@shadow: 0 -16px 28px 0 fade(@shadow-color, 22%), 0 -25px 55px 0 fade(@shadow-color, 21%);
			.box-shadow(@shadow);
		}
		& when (@shadow-style = top6) {
			@shadow: 0 -27px 24px 0 fade(@shadow-color, 20%), 0 -40px 77px 0 fade(@shadow-color, 22%);
			.box-shadow(@shadow);
		}

		& when (@shadow-style = chatbot) {
			@shadow: 0 0 0 0 fade(@shadow-color, 0), 0 0 50px 0 fade(@shadow-color, 25%);
			.box-shadow(@shadow);
		}
	}
}

//## Check right unit

.check-unit(
	@property;
	@unit;
) {
	@unit-pixel: 1px;
	.process(@unit) when (ispixel(@unit)), (isem(@unit)), (ispercentage(@unit)), (iskeyword(@unit)){
		@checked-unit: @unit;
		& when not (@property = none) {
			@{property}: @checked-unit;
		}
	}

	.process(@unit) when not (ispixel(@unit)) and not (isem(@unit)) and not (ispercentage(@unit)) and not (isstring(@unit)) and not (iskeyword(@unit)) {
		// @checked-unit: ~`@{unit} + @{unit-pixel}`;
		@checked-unit: @unit * @unit-pixel;

		& when not (@property = none) {
			@{property}: @checked-unit;
		}
	}
	.process(@unit);
}

//## MIXIN Modal overlay
.container-overlay(
	@popup-backdrop-bg;
	@top-pos: 0;
) {
	overflow: hidden;
	position: fixed;
	.check-unit(
		@property: top;
		@unit: @top-pos;
	);
	.r(0);
	.l(0);
	.b(0);
	z-index: @zindex-modal-background;
	-webkit-overflow-scrolling: touch;

	overflow-x: hidden;
	overflow-y: auto;
	width: 100% !important;
	> div[class*="overlay"] {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		.square(100%);
		z-index: @zindex-modal-background;
		background-color: @popup-backdrop-bg;
	}
	// &.header-search,
	// &.header-nav {
	// 	.transition(@transition-base);
	// 	.transition-property(opacity);
	// 	.opacity(0);
	// }
	// &.is-active {
	// 	.opacity(1);
	// }
}


//## mixin circular loading bar
//--
.loading-bar(
	@loading-color;
	@loading-width;
	@loading-height;
	@loading-position;
	@loading-spacing;
	@loading-style: circle;
) {
	//## #loading-bar
	display: none;
	position: absolute;
	//debug
	// &:after {
	// 	content: "@{loading-position}";
	// }

	& when (@loading-position = right) {
		& when (@search-text) {
			// .r(@loading-spacing + @search-padding-horizontal);
		}
		& when not (@search-text) {
			// .r(@search-height-small + @loading-spacing + @search-padding-horizontal + @search-btn-close-width);
			@media (min-width: @grid-float-breakpoint) {
				.r(@search-height + @loading-spacing + @search-padding-horizontal);

			}

		}
	}

	// & when (@loading-position = left) {
	// 	& when (@search-text) {
	// 		.l(@loading-spacing + @search-padding-horizontal);
	// 	}
	// 	& when not (@search-text) {
	// 		.l(@search-height + @search-padding-horizontal)
	// 	}
	// }
	.t(0);
	.size(@loading-width; 100%);

	> .loading-element {
		.list-unstyled();
		.align-block(
			@position: absolute;
			@position-v: center;
			@position-h: left;
		);

		.size(@loading-width; auto);
		display: block;
	}

	& when (@loading-style = circle) or (@loading-style = circle3){
		> .loading-element {
			& when	(@loading-style = circle){
				> .loading-child {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					&:before {
						content: '';
						display: block;
						margin: 0 auto;
						width: 15%;
						height: 15%;
						background-color: @loading-color;
						.border-radius(100%);
						.animation(loading-circleBounceDelay 1.2s infinite ease-in-out both);
					}
				}
			}
			& when	(@loading-style = circle3){
				> .loading-child {
					width: 100%;
					height: 100%;
					position: absolute;
					left: 0;
					top: 0;
					&:before {
						content: '';
						display: block;
						margin: 0 auto;
						width: 15%;
						height: 15%;
						background-color: @loading-color;
						.border-radius(100%);
						.animation(loading-circleOpacityDelay 1.2s infinite ease-in-out both);
					}
				}
			}
			> .loading-element2 {
				.rotate(30deg);
				&:before {
					.animation-delay(-1.1s);
				}
			}
			> .loading-element3 {
				.rotate(60deg);
				&:before {
					.animation-delay(-1s);
				}
			}
			> .loading-element4 {
				.rotate(90deg);
				&:before {
					.animation-delay(-0.9s);
				}
			}
			> .loading-element5 {
				.rotate(120deg);
				&:before {
					.animation-delay(-0.8s);
				}
			}
			> .loading-element6 {
				.rotate(150deg);
				&:before {
					.animation-delay(-0.7s);
				}
			}
			> .loading-element7 {
				.rotate(180deg);
				&:before {
					.animation-delay(-0.6s);
				}
			}
			> .loading-element8 {
				.rotate(210deg);
				&:before {
					.animation-delay(-0.5s);
				}
			}
			> .loading-element9 {
				.rotate(240deg);
				&:before {
					.animation-delay(-0.4s);
				}
			}
			> .loading-element10 {
				.rotate(270deg);
				&:before {
					.animation-delay(-0.3s);
				}
			}
			> .loading-element11 {
				.rotate(300deg);
				&:before {
					.animation-delay(-0.2s);
				}
			}
			> .loading-element12 {
				.rotate(330deg);
				&:before {
					.animation-delay(-0.1s);
				}
			}
		}
	}

	& when (@loading-style = circle2) {
		.loading-element > .loading-child{
			.square((@loading-width / 3) - @loading-padding);
			background-color: @loading-color;

			+ .loading-child{
				margin-left: @loading-padding;
			}
			border-radius: 100%;
			.pull-left;
			// display: inline-block;

			.animation(loading-circleBounceDelay 0.8s infinite ease-in-out both);
		}

		.loading-element .loading-element2 {
		.animation-delay(-0.7s);
		}

		.loading-element .loading-element3 {
		.animation-delay(-1.4s);
		}

		.loading-element4, .loading-element5, .loading-element6, .loading-element7, .loading-element8, .loading-element9, .loading-element10, .loading-element11, .loading-element12{display: none !important;}
	}

	& when (@loading-style = box) {
		.loading-element1 {
			width: 100%;
			height: 100%;
			background-color: @loading-color;

			.animation(loading-rotateplane 1.2s infinite ease-in-out both);
		}

		.loading-element2, .loading-element3, .loading-element4, .loading-element5, .loading-element6, .loading-element7, .loading-element8, .loading-element9, .loading-element10, .loading-element11, .loading-element12{display: none;}
	}

	& when (@loading-style = bounce) {
		.loading-element1, .loading-element2 {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: @loading-color;
			opacity: 0.6;
			position: absolute;
			top: 0;
			left: 0;

			.animation(loading-bounce 1.2s infinite ease-in-out both);
		}

		.loading-element2 {
			.animation-delay(-0.6s);
		}

		.loading-element3, .loading-element4, .loading-element5, .loading-element6, .loading-element7, .loading-element8, .loading-element9, .loading-element10, .loading-element11, .loading-element12{display: none;}


		
		@keyframes sk-bounce {
			0%, 100% {
			transform: scale(0.0);
			-webkit-transform: scale(0.0);
		} 50% {
			transform: scale(1.0);
			-webkit-transform: scale(1.0);
		}
		}
	}

	& when (@loading-style = rectangles) or (@loading-style = rectangles2){
		.loading-element > .loading-child{
			background-color: @loading-color;
			height: 100%;
			width: (@loading-width / 5) - @loading-padding;
			+ .loading-child{
				margin-left: @loading-padding;
			}
			display: inline-block;

			& when (@loading-style = rectangles){
				.animation(loading-rectangles 0.8s infinite ease-in-out both);
			}
			& when (@loading-style = rectangles2){
				.animation(loading-rectangles2 1.4s infinite ease-in);
			}
		}

		& when (@loading-style = rectangles2){
			.loading-element .loading-element1{
				display: none;
			}
		}

		.loading-element .loading-element2 {
			.animation-delay(-0.7s);
		}

		.loading-element .loading-element3 {
			.animation-delay(-0.6s);
		}

		.loading-element .loading-element4 {
			.animation-delay(-0.5s);
		}

		.loading-element .loading-element5 {
			.animation-delay(-0.4s);
		}

		.loading-element6, .loading-element7, .loading-element8, .loading-element9, .loading-element10, .loading-element11, .loading-element12{display: none !important;}
	}

	//-- Keyframes
	.keyframes(~'loading-circleBounceDelay, 0%, 80%, 100%{ transform: scale(0); } 40%{ transform: scale(1); }');
	.keyframes(~'loading-circleOpacityDelay, 0%, 39% ,100%{ opacity: 0; } 40%{ opacity: 1; }');
	.keyframes(~'loading-rotateplane, 0%{ transform: perspective(120px); } 50%{ transform: perspective(120px) rotateY(180deg); } 100%{ transform: perspective(120px) rotateY(180deg) rotateX(180deg);}');
	.keyframes(~'loading-rotateplane, 0%{ transform: perspective(120px) rotateY(0deg) rotateX(0deg); } 50%{ transform: perspective(120px) rotateY(0deg) rotateX(-180.1deg); } 100%{ transform: perspective(120px) rotateY(-179.9deg) rotateX(-180deg);}');
	.keyframes(~'loading-bounce, 0%, 50%, 100%{ transform: scale(0); } 50%{ transform: scale(1); } 100%{ transform: scale(0); }');
	.keyframes(~'loading-rectangles, 0%, 100%{ transform: scaleY(0); } 50%{ transform: scaleY(1.1);}');
	.keyframes(~'loading-rectangles2, 0%, 100%{ transform: scaleY(1); } 50%{ transform: scaleY(0);}');
}


.animated-underline(
	@a-underline-color: @link-hover-color;
	@animation-style: fadeup;
	@animation-speed: @transition-base-duration;
	@a-underline-thickness: 1px;
	@a-underline-spacer: 2px;
) {
	.text-none;
	> span {
		position: relative;
		display: inline-block;
		.pb(@a-underline-spacer);
		&:after{
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			.size(100%;@a-underline-thickness);
			background-color: @a-underline-color;
			& when (@animation-style = fadeup) {
				.transition(all @animation-speed);
				.opacity(0);
				.translateY(4px);
			}
		}
	}
	&:hover,
	&:focus {
		.text-none;
		> span {
			&:after{
				.opacity(1);
				.translateY(0px);
			}
		}
	}
}


//## Mixin format-date-style
.partial-format-date-style(
	@partial-type: global;
	@is-global-partial-style: true;
	@entry-date-position: @icon-position;
	@entry-date-bg: @entry-date-bg;
	@entry-date-border: @entry-date-border;
	@entry-date-font-color: @entry-date-font-color;
	@entry-date-size: @entry-date-size;
	@entry-date-width: @entry-date-width;
	@entry-date-border-radius: @entry-date-border-radius;
	@entry-date-position-horizontal: @entry-date-position-horizontal;
	@entry-date-position-vertical: @entry-date-position-vertical;
) {
	& when not (@is-global-partial-style) {
		.partial-block-date {
			.entry-date {
				color: @entry-date-font-color;
				.border-radius(@entry-date-border-radius);
				.check-unit(
					@property: width;
					@unit: @entry-date-width;
				);
				.bg-color( @entry-date-bg);
				border-color: @entry-date-border;
				.datetext {
					.font-size(@entry-date-size - 2);
				}
				.day {
					.font-size(@entry-date-size);
				}
				.month {
					.font-size(@entry-date-size - 6);
				}
			}
		}

		.list {
			> li > a {
				&:hover,
				&:focus {
					.partial-block-date {
						.entry-date {
							color: @entry-date-font-hover-color;
							.bg-color(@entry-date-bg-hover);
							border-color: @entry-date-bg-hover;
							// .month { // background: darken(@events-bg,17%); }
						}
					}
				}
			}
		}
	}

	//## specifieke partial list styling
	.has-media,
	.no-media {
		position: relative;
		.partial-block-date {
			position: absolute;
			z-index: 3;
			& when (@entry-date-position = left) {
				right: auto;
				.l(@entry-date-position-horizontal);
			}
			& when (@entry-date-position = right) {
				left: auto;
				.r(@entry-date-position-horizontal);
			}
			.t(@entry-date-position-vertical);
		}
	}
	& when (@partial-type = overview) or (@partial-type = spotlight) {
		//--indien geen image
		.no-media {
			.partial-block-date {
				position: relative;
				top: auto;
				& when (@entry-date-position = left) {
					left: auto;
					.pull-left;
					.mr(@grid-gutter-width-partial);
				}
				& when (@entry-date-position = right) {
					right: auto;
					.pull-right;
					.ml(@grid-gutter-width-partial);
				}
				.mb(0); //.mb(@grid-gutter-width-partial/2);
				+ .partial-content-block {
					> .partial-content {
						//--alignering t.o.v formatblock date text
						// .pt(@entry-date-vertical);
						// clear styling content (title + info)
						.no-border;
						.title {
							.check-unit(
								@property: line-height;
								@unit: floor((@entry-date-size * @line-height-base));
							) !important;
						}
					}
				}
			}
		}
	}
}

//## mixin to iterate over colors and create CSS class for each one
.make-class-style(
	@prefix;
	@list;
	@property;
) {
	.iter(length(@list));
	.iter(@i) when (@i > 0) {
		.iter(@i - 1);
		@pair: extract(@list, @i);
		@key: extract(@pair, 1);
		@value: extract(@pair, 2);

		&.@{prefix}-@{i} {
			@{property}: @value;
		}
		&:hover,
		&:focus {
			&.@{prefix}-@{i} {
				@{property}: darken(@value, 10%);
			}
		}
	}
}


//voorbeeld: .triangle(up, 1em, 7px, @color1-base);
.triangle-base() {
	content: '';
	display: block;
	width: 0;
	height: 0;
	-moz-transform: scale(.9999);
}
.triangle(@direction, @size, @color) {
	.triangle(@direction, @size * 2, @size, @color);
}
.triangle(@direction, @width, @height, @color) when (@direction = up) {
	.triangle-base();
	border-left: (@width / 2) solid transparent;
	border-right: (@width / 2) solid transparent;
	border-bottom: @height solid @color;
}
.triangle(@direction, @width, @height, @color) when (@direction = down) {
	.triangle-base();
	border-left: (@width / 2) solid transparent;
	border-right: (@width / 2) solid transparent;
	border-top: @height solid @color;
}
.triangle(@direction, @width, @height, @color) when (@direction = left) {
	.triangle-base();
	border-top: (@width / 2) solid transparent;
	border-bottom: (@width / 2) solid transparent;
	border-right: @height solid @color;
}
.triangle(@direction, @width, @height, @color) when (@direction = right) {
	.triangle-base();
	border-top: (@width / 2) solid transparent;
	border-bottom: (@width / 2) solid transparent;
	border-left: @height solid @color;
}

.scrollbar-properties() {
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	/* Make an auto-hiding scroller for the 3 people using a IE */
	-ms-overflow-style: -ms-autohiding-scrollbar;
	/* Remove the default scrollbar for WebKit implementations */
	&::-webkit-scrollbar {
		display: none;
	}

	&.is-touch {
		overflow-y: scroll;
	}
	overflow-x: hidden;
}

//## Mixin scrollbar
.scrollbar-styling(
	@scrollbar-size: 20px;
	@scrollbar-foreground-color: darken(@gray-light, 15%);
	@scrollbar-background-color: @gray-light;
) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width:  @scrollbar-size;
        height: @scrollbar-size;
    }

    &::-webkit-scrollbar-thumb {
        background: @scrollbar-foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: @scrollbar-background-color;
    }

    // For firefox
    scrollbar-width: thin;
    scrollbar-color: @scrollbar-foreground-color @scrollbar-background-color;
}

.make-hsl(
	@property: color;
	@color: @brand-primary;
	@alpha: 1;
) {
	@h: hue(@color) + 40;
	@s: saturation(@color) - 5;
	@l: lightness(@color) + 30;
	@color-hsl:  hsla(@h,@s,@l, @alpha);
	& when not (@property = none) {
		@{property}: @color-hsl;
	}
}

.page-content-loading(
	@loading-color: @brand-primary;
	@width: 37px;
	@height: 20px;
) {
	@loading-color-fade: fade(@loading-color, 20%);
	position: relative;
	.check-unit(
		@property: height;
		@unit: @height;
	);
	> ul {
		.check-unit(
			@property: width;
			@unit: @width;
		);
		.align-horizontal(
			@position: absolute;
			@position-h: center;
		);
		.list-unstyled();
		> li {
			display: inline-block;
			.square(7);
			.bg-color(@loading-color-fade);
			+ li {
				.ml(1);
			}

			&:nth-child(1){
				.animation(overview-loading-1 1s infinite ease);
			}

			&:nth-child(2){
				.animation(overview-loading-1 1s infinite ease);
				.animation-delay(0.4s);
			}

			&:nth-child(3){
				.animation(overview-loading-1 1s infinite ease);
				.animation-delay(0.8s);
			}

			&:nth-child(4){
				.animation(overview-loading-1 1s infinite ease);
				.animation-delay(1s);
			}
		}
	}

	.keyframes(~'overview-loading-1, 0%, 90% ,100%{ background-color: @{loading-color-fade} } 40%{ background-color: @{loading-color}; }');
}





/* Animacao background */

.lazycolor {
	.opacity(.8);
	background: linear-gradient(135deg, rgba(211,223,232,1) 30%,@highlight-bg 38%,@color2-light 41%,@color2-light 50%,@color2-light 50%,@highlight-bg 51%,rgba(211,223,232,1) 57%,rgba(211,223,232,1) 80%,rgba(211,223,232,1) 80%);
	background-size: 1200% 1200%;

	-webkit-animation: AnimationName 2s ease infinite reverse;
	-moz-animation: AnimationName 2s ease infinite reverse;
	animation: AnimationName 2s ease infinite reverse;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 92%}
    50%{background-position:100% 9%}
    100%{background-position:0% 92%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 92%}
    50%{background-position:100% 9%}
    100%{background-position:0% 92%}
}
@keyframes AnimationName { 
    0%{background-position:0% 92%}
    100%{background-position:100% 9%}
/*     100%{background-position:0% 92%} */
}