/*****************
* BUTTON MIXINS *
*****************/
.btn(
	@font-weight: @btn-font-weight;
) {
	display: inline-block;
	.mb(0); // For input.btn

	& when(@font-weight = base) {
		.text-normal;
	}
	& when(@font-weight = light) {
		.text-light;
	}
	& when(@font-weight = semibold) {
		.text-semibold;
	}
	& when(@font-weight = bold) {
		.text-bold;
	}
	& when(@font-weight = custom) {
		.text-custom;
	}
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
	border: @btn-border-width solid @border-color;
	// border-left: 0px solid @border-color;
	// border-bottom: @btn-border-width solid @border-color;
	// border-right: 0px solid @border-color;
	// border-right: 0px solid @border-color;
	// white-space: nowrap;
	.button-size(
		@padding-vertical: @btn-padding-vertical;
		@padding-horizontal: @btn-padding-horizontal;
		@font-size: @font-size-base;
		@line-height: @line-height-computed;
		@border-radius: @btn-border-radius-base;
	);
	.user-select(none);

	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			.tab-focus();
		}
	}



	&:active,
	&.active {
		outline: 0;
		background-image: none;
		// .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: @cursor-disabled;
		.opacity(.65);
		.box-shadow(none);
	}

	// a& {
	// 	&.disabled,
	// 	fieldset[disabled] & {
	// 		pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
	// 	}
	// }
}

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
.button-variant(
	@color;
	@background;
	@border;
	@is-hover;
) {
	color: @color;
	background-color: @background;
	border-color: @border;
	& when (@is-prototype) {
		color: contrast(@background);
	}
	&:hover, 
	&:focus {
		.text-none;
	}
	&:focus {
		.outline-contrast-color(@background);
	}
	& when (@is-hover = true) {
		.transition(@transition-base);
		.transition-property(color, background, border);
		&:focus,
		&:hover {
			color: @color;
			background-color: darken(@background, 10%);
			border-color: darken(@border, 12%);
			& when (@is-prototype) {
				color: contrast(darken(@background, 10%));
			}
		}
		&:focus {
			.outline-contrast-color(darken(@background, 10%));
		}

		&:active{
			color: @color;
			background-color: darken(@background, 10%);
			border-color: darken(@border, 12%);
			& when (@is-prototype) {
				color: contrast(darken(@background, 10%));
			}

			&:hover,
			&:focus {
				color: @color;
				background-color: darken(@background, 17%);
				border-color: darken(@border, 25%);
			}
		}
		&:active {
			background-image: none;
		}
		&.disabled,
		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus,
			&.focus {
				background-color: @background;
				border-color: @border;
			}
		}
	}
}

.button-hover-variant(
	@color;
	@background;
	@border;
) {
	.transition(@transition-base);
	.transition-property(color, background, border);
	&:hover,
	&:focus {
		color: @color;
		background-color: @background; //darken(@background, 10%);
		border-color: @border; //darken(@border, 25%);
		& when (@is-prototype) {
			color: contrast(@background);
		}
	}
	&:focus {
		.outline-contrast-color(@background);
	}
}

// Button sizes
.button-size(
	@padding-vertical;
	@padding-horizontal;
	@font-size;
	@line-height;
	@border-radius;
) {
	.pv(@padding-vertical);
	.ph(@padding-horizontal);
	// .font-size(@font-size;true;global);
	.font-size(@font-size);
	.check-unit(
		@property: line-height;
		@unit: @line-height;
	) ;
	.border-radius(@border-radius);
}

// Make a button look and behave like a link
.btn-link(
	@btn-link-color: @link-color;
	@btn-link-hover-color: @link-hover-color;
	@is-hover;
) {
	color: @btn-link-color;
	.border-radius(0);
	.ph(0);
	white-space: normal;
	.text-left;
	// padding: 0;
	& when (@is-hover = true) {
		&,
		&:active,
		&.active,
		&[disabled],
		fieldset[disabled] & {
			background-color: transparent;
			.box-shadow(none);
		}

		&,
		&:hover,
		&:focus,
		&:active {
			border-color: transparent;
		}
		&:hover,
		&:focus {
			color: @btn-link-hover-color;
			text-decoration: @link-hover-decoration;
			background-color: transparent;
		}

		&[disabled],
		fieldset[disabled] & {
			&:hover,
			&:focus {
				color: @btn-link-disabled-color;
				.text-none;
			}
		}
	}
	//used indien icon wordt toegevoegd op een button voor mooie basis uitlijning
	// &[class^="icon-"], &[class*=" icon-"],
	// &[class^="iconcustom-"], &[class*=" iconcustom-"] {
	// 	.icon-btn-position(
	// 		@icon-btn-position-horizontal: 0;
	// 		@icon-btn-position-vertical: 0;
	// 		@icon-position;
	// 		@btn-text;
	// 		@text-padding: @padding-xs-horizontal;
	// 		@icon-width;
	// 		@icon-height;
	// 		@icon-btn-align: vcenter;
	// 	);
	// }
}

// Mixin for generating new styles
.btn-styles(
	@btn-color: #555
) {
	#gradient > .vertical(
		@start-color: @btn-color;
		@end-color: darken(@btn-color, 12%)
	);
	.reset-filter(); // Disable gradients for IE9 because filter bleeds through rounded corners; see https://github.com/twbs/bootstrap/issues/10620
	background-repeat: repeat-x;
	border-color: darken(@btn-color, 14%);
	// .transition(@transition-base);
	.transition-property(color, background);
	&:hover,
	&:focus  {
		#gradient > .vertical(
			@start-color: darken(@btn-color, 12%);
			@end-color: @btn-color;
		);
	}

	&:active,
	&.active {
		background-color: darken(@btn-color, 12%);
		border-color: darken(@btn-color, 14%);
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: darken(@btn-color, 12%);
			background-image: none;
		}
	}
}
