//
// Utility classes
// --------------------------------------------------

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
	.img-responsive();
}
// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute !important;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
	display: inherit !important;
}

.sr-visible {
	position: static !important;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable(@position: static) {
	&:active,
	&:focus {
		position: @position !important;
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		clip: auto;
	}
}

.invisibility {
	visibility: hidden !important;
}

// Floats
// -------------------------

.clearfix {
	.clearfix();
}
.center-block {
	.center-block();
}
.pull-right {
	float: right !important;
}
.pull-left {
	float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide, 
.masked {
	display: none !important;
}
.show {
	display: block !important;
}
.invisible {
	visibility: hidden;
}
.text-hide {
	.text-hide();
}

.hide-mobile{
	@media (max-width: @screen-phone-max) {
		.hide;
	}
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
	display: none !important;
}


// For Affix plugin
// -------------------------

.affix {
	position: fixed;
}
