/**********************
* Fonts // Typography *
**********************/
//-- conditionals --//
@font-use-mediaqueries: 		false;
@font-use-global-mediaqueries: 	false;

// global fonts - backup fonts
@font-family-sans-serif: 	Arial, Helvetica, "Helvetica Neue", sans-serif;
@font-family-serif: 		Georgia, "Times New Roman", Times, serif;

.setPortalfamily();

//voor print en prototype
.setPortalfamily() when (@print-css) or (@is-prototype) {
	//## Typography
	@font-family-light: 			@font-family-base;
	@font-family-base: 				@font-family-sans-serif;
	@font-family-semibold:			@font-family-base;
	@font-family-bold:				@font-family-base;

	//##font-weight variables
	@font-weight-light: 			@font-weight-base;
	@font-weight-base: 				normal;
	@font-weight-semibold: 			@font-weight-bold;
	@font-weight-bold: 				bold;

	@headings-font-family: 			@font-family-bold;
	@headings-font-weight: 			@font-weight-bold;

	//## Custom font gelijk zetten als die van google.
	@font-custom-light:				@font-family-light;
	@font-custom-base: 				@font-family-base;
	@font-custom-semibold:			@font-family-semibold;
	@font-custom-bold:				@font-family-bold;

	@font-weight-custom-light: 		@font-weight-light;
	@font-weight-custom-base: 		@font-weight-base;
	@font-weight-custom-semibold: 	@font-weight-semibold;
	@font-weight-custom-bold: 		@font-weight-bold;
}

//## Typography
@font-size-base: 			17px;
@font-size-normal: 			18px;//@font-size-base;
@font-size-large: 			ceil((@font-size-base * 1.25)); // ~20px
@font-size-small: 			ceil((@font-size-base * 0.85)); // ~13.6px

//## font-size variables
@font-size-h1: 				34px; //floor((@font-size-base * 2.3)); // ~36px
@font-size-h2: 				26px; //floor((@font-size-base * 2.15)); // ~30px
@font-size-h3: 				22px; //ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4: 				18px; //ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5: 				@font-size-base;
@font-size-h6: 				@font-size-base; //ceil((@font-size-base * 0.85)); // ~12px
