/*******************************************
* CORE :: STRUCTURE CHANGES :: SCAFFOLDING*
********************************************/
* {
	&, 
	&:before, 
	&:after {
		.box-sizing(inherit);
	}
} 

//== Body
//
html,
body {
	height: 100%;
	// scroll-behavior: smooth;
	
}

html {
	.box-sizing(border-box);
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	&.no-scroll {
		overflow: hidden;
		position: relative;
	}
}

//## Body reset
body { 
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	.text-base;
	color: @text-color;
	.font-size(
		@font-size-base;
		@font-use-mediaqueries: @font-use-global-mediaqueries;
		@fontsize-style: global;
	);
	& when not (@font-use-global-mediaqueries) {
		.check-unit(
			@property: line-height;
			@unit: @line-height-computed;
		);
	}
	.bg-color(@body-bg);
	&.animate {
		a,
		button {
			.transition(@transition-base);
			.transition-property(background-color, color, border-color);
		}
	}
    //-- styling base error pages --//
	&.error {
		#outer-wrap,
		#inner-wrap,
		.page-container-wrapper,
		.page-inner {
			height: 100%;
			.bg-color(@btn-primary-bg);
			.btn-back {
				.btn-link(
					@btn-link-color: @btn-primary-color;
					@btn-link-hover-color: contrast(@btn-primary-bg);
					@is-hover;
				);
			}
		}
	}
}

//-- Reset fonts for relevant elements --//
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

//-- Links override --//
a {
	color: @link-color;
	.text-none;
	&:hover,
	&:focus {
		color: @link-hover-color;
		text-decoration: @link-hover-decoration;
	}

	&:focus {
		.tab-focus();
	}
}

// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

figure {
	margin: 0;
}

// Images
img {
	vertical-align: middle;
}


// Horizontal rules
hr {
	.mv(@line-height-computed);
	border: 0;
	border-top: 1px solid @hr-border;
	&.mini {
		width: 5%;
		margin: 0 auto;
		border-width: 4px;
	}
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
	cursor: pointer;
}