// WebKit-style focus

.tab-focus() {
	// Default
	outline: thin dotted @outline-color;
	outline-width: 2px;
	// WebKit
    // outline: auto 5px -webkit-focus-ring-color;
	outline-offset: -2px;
}

.outline-contrast-color(@color) { 
	outline-color: contrast(@color);
}

.outline-offset(@offset-width: -2px) { 
	.check-unit(
		@property: outline-offset;
		@unit: @offset-width;
	);
}
