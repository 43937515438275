/**************
* List mixins *
**************/

// Unstyled keeps list items block level, just removes default browser padding and list-style
.list-unstyled() {
	.pl(0);
	list-style: none;
}

/*******************************************
* base list-icon styling met enkele anchor *
*******************************************/
.list-icon(
	@icon-list-color;
	@list-text-color;
	@icon-list;
	@icon-list-size;
	@use-custom-icon: @use-custom-list-icon;
) {
	&,
	ul {
		.list-unstyled();
		.mb(0);
	}
	li {
		.icon(
			@icon-symbol: @icon-list;
			@icon-position;
			@use-custom-icon;
		);
		.icon-position(
			@icon-position-horizontal: @icon-list-position-horizontal;
			@icon-position-vertical;
			@icon-position;
		);

		//om de link volledig aanklikbaar te maken
		a {
			position: relative;
			z-index: 1;
		}
		.icon-style(
			@icon-color: @icon-list-color;
			@icon-size: @icon-list-size;
			@icon-position;
		);

		> a {
			display: block;
			color: @list-text-color;
			.icon-btn-text-position(
				@text-padding: @icon-list-position-horizontal + @list-text-padding;
				@icon-width: @icon-list-width;
				@icon-position;
			);
			// z-index: 1;
		}
		ul {
			.pl(@icon-list-position-horizontal + @list-text-padding + @icon-list-width);
		}
		&:hover {
			.icon-color-variant(
				@icon-color: @link-hover-color;
				@icon-position;
			);
			> a {
				&:not([class*="btn-"]) {
					&:only-child {
						color: @link-hover-color;
						.text-underline;
					}
				}
			}
		}
	}
}

//## custom icon list styling (link enkel clickable)
.list-icon-variant(
	@icon-list-color: @icon-list-color;
	@list-text-color: @list-text-color;
	@icon-list: @icon-list;
	@icon-list-size: @icon-list-size;
	@icon-position: @icon-position;
	@text-padding: @list-text-padding;
	@icon-width: @icon-list-width;
) {
	.list-icon(
		@icon-list-color;
		@list-text-color;
		@icon-list;
		@icon-list-size;
	);
	> li {
		color: @list-text-color;
		.pl(@icon-width + @text-padding);
		> a {
			display: inline-block;
			.p(0);
			z-index: inherit;
		}
	}
}

// Inline turns list items into flex
.list-inline(
	@has-icon-inline;
	@list-gutter: @grid-gutter-width;
	@icon-inline: @icon-inline;
	@icon-inline-color: @icon-inline-color;
	@icon-inline-size: @icon-inline-size;
	@icon-position: @icon-position;
	@use-custom-icon: false;
) {
	.list-unstyled();
	.mb(0);
	.flex-display();
	.flex-wrap(wrap);
	// .align-items(center);
	> * {
		.flex-display();
		+ * {
			// .mt(0);
			// //## icon serperator
			& when (@has-icon-inline) {
				.icon-inline(
					@icon-inline: @icon-inline;
					@icon-position: @icon-position;
					@inline-gutter: @list-gutter;
					@use-custom-icon: @use-custom-icon;
				);
				.flex-wrap(nowrap);

				.icon-style(
					@icon-color: @icon-inline-color;
					@icon-size: @icon-inline-size;
					@icon-position: @icon-position;
				);
			}
		}
		& when not (@has-icon-inline) and (@list-gutter > 0) {
			&:not(:last-child) {
				.mr(@list-gutter);
			}
		}
	}
}

//## Mixin dl-horizontal-style on contact-info ( zie contact detail less)

.dl-horizontal-style(
	@dl-spacer: @dl-horizontal-offset
) {
	dd {
		&:extend(.clearfix all); // Clear the floated `dt` if an empty `dd` is present
	}

	dt {
		float: left;
		width: (@dl-spacer * 1px) - (@grid-gutter-width/2);
		clear: left;
		text-align: left;
		// .text-overflow();
	}

	dd {
		.ml(@dl-spacer);
	}
}

.list-horizontal-styling(
	@title-width: @component-offset-horizontal;
) {
	.list {
		.list-unstyled();
		&:not(:last-child) {
			.mb(@spacing-vertical);
		}
		> li {
			.pv(@padding-base-vertical);
			.flex-display(flex);
			.flex-direction(column);
			@media (min-width: @screen-tablet) {
				.flex-direction(row);
				> [class*="txt-"]:first-child {
					.flex(0 0 @title-width);
					.mr(@list-text-padding);
				}
			}
			> [class*="txt-"]:first-child {
				// .heading-style(inherit;@font-size-base;0);
				.text-bold;
				&:first-letter {
					.text-uppercase;
				}
			}

			+ li {
				border-top: 1px dotted @box-hr-border;
			}
		}
	}
}

/************************
* list overview styling *
************************/
//-- list overview mixin global --//
.list-overview-style(
	@list-overview-style: list;
	@list-overview-type: global;
) {
	//List base check/stylinglist
	.list-icon(
		@icon-list-color;
		@list-text-color;
		@icon-list;
		@icon-list-size;
		@use-custom-icon: @use-custom-list-icon;
	);

	//
	//##base list overview style
	> li {
		> a {
			.pb(@list-overview-padding-vertical);
		}
	}

	& when (@list-overview-style = grid) {
		//-- grid met bullets

		> li {
			.mb(@spacing/2);
			.icon-position(
				@icon-position-horizontal: (@grid-gutter-width/2);
				@icon-position-vertical: -1px;
				@icon-position;
			);
		}
	}

	// & when not (@list-overview-type = grid-theme) {}

	//## list-overview-style =  list
	& when (@list-overview-style = list) {
		//-- styling list-keywords op az index pagina on products, services, eloket, company, associations
		& when (@list-overview-type = azlist) {
			> li {
				.icon-alt-variant(
					@icon-symbol: @icon-base;
					@icon-position;
					@use-custom-icon: @use-custom-list-icon;
				);
				.icon-size-variant(
					@icon-size: @icon-list-size;
					@icon-position: @icon-position;
					@font-size-style: icon;
				);
				.icon-color-variant(
					@icon-color: @az-base-color;
					@icon-position;
				);
				> a {
					.icon-btn-text-position(
						@text-padding: @icon-list-position-horizontal + @list-text-padding;
						@icon-width: 7;
						@icon-position;
					);
				}
			}
		}

		& when (@list-overview-type = box) {
			&:not(:last-child) {
				.mb(@spacing-vertical);
			}
			> li {
				// &:before{
				// 	left: -5px;
				// }
				.icon-position(
					@icon-position-horizontal: 0px;
					@icon-position-vertical: -3px;
					@icon-position;
				);
				.icon-color-variant(
					@icon-color: @brand-secondary;
					@icon-position;
				);
				> a {
					color:  @box-text-color;
				}
			}
		}
		& when (@list-overview-type = variant) {
			li {
				.pl(@icon-list-position-horizontal + @list-text-padding + @icon-list-width);
				&:hover {
					.icon-color-variant(
						@icon-color: @icon-list-color;
						@icon-position;
					);
				}
				> a {
					display: inline-block;
					.p(0);
				}
				ul {
					.pl(0);
				}
			}
			> li {
				//eventueel andere stijl sub items
				ul {
					> li {
						.icon-alt-variant(
							@icon-symbol: @icon-base;
							@icon-position;
							@use-custom-icon: @use-custom-list-icon;
						);
						.icon-size-variant(
							@icon-size: @icon-size-base;
							@icon-position: @icon-position;
							@font-size-style: icon;
						);
						.pl(@icon-list-position-horizontal + @list-text-padding + 7);
					}
				}
			}
		}
		& when (@list-overview-type = file) {
			> li {
				.icon-size-variant(
					@icon-size: @icon-size-base;
				);
				.icon-color-variant(
					@icon-color: @icon-base-color;
					@icon-position: @icon-position;
				);
				// > a {
				// 	.icon-btn-text-position(
				// 		@text-padding: @icon-list-position-horizontal + @list-text-padding;
				// 		@icon-width;
				// 		@icon-position;
				// 	);
				// }
			}
		}
		// & when (@list-overview-type = notification) { }
	}


	//## list-overview-style =  grid
	& when (@list-overview-style = grid) {
		& when (@list-overview-type = global) {
			#grid-sections > .make-column-grid(
				@page-section: list-grid;
				@column-width: @grid-gutter-width;
				@grid-style: flex;
			);
			//overrule base list-icon
			// > li {
			// 	.icon-alt-variant(
			// 		@icon-symbol: @icon-base;
			// 		@icon-position;
			// 		@use-custom-icon: @use-custom-list-icon;
			// 	);
			// 	.icon-size-variant(
			// 		@icon-size: @icon-size-base;
			// 		@icon-position: @icon-position;
			// 		@font-size-style: icon;
			// 	);
			// 	> a {
			// 		.icon-btn-text-position(
			// 			@text-padding: @icon-list-position-horizontal + @list-text-padding;
			// 			@icon-width: 7;
			// 			@icon-position;
			// 		);
			// 	}
			// }
		}

		& when not (@list-overview-type = global) {
			//-- check theme sortable
			#grid-sections > .make-column-grid(
				@page-section: @list-overview-type;
				@column-width: @grid-gutter-width;
				@grid-style: flex;
			); //-- namespaces zie mixin lcp grid less
		}
	}

	//## list-overview-style =  grid-full
	& when (@list-overview-style = grid-full) {
		& when (@list-overview-type = global) {
			/////////CHOSE////////
			///// FLEX & GRID/////
			#grid-sections > .make-column-grid(
				@page-section: list-grid;
				@column-width: @grid-gutter-width;
				@grid-style: grid-full;
			);
			// > li {
			// 	.icon-reposition(
			// 		@icon-position-horizontal: 15;
			// 		@icon-position-vertical;
			// 		@icon-position;
			// 		@position-horizontal: @icon-position;
			// 		@position-vertical: top;
			// 	);
			// }
		}

		& when not (@list-overview-type = global) {
			//-- check theme sortable
			#grid-sections > .make-column-grid(
				@page-section: @list-overview-type;
				@column-width: @grid-gutter-width;
			); //-- namespaces zie mixin lcp grid less
		}
	}

	& when (@list-overview-type = sitemap) {
		.mb(@spacing);
		> li {
			.icon-alt-variant(
				@icon-symbol: "";
			);
			&:not(:last-child) {
				.mb(@spacing-paragraph * 2);
			}

			> a {
				//-- styling hoofd items
				.title;
				.text-title;
				.font-size(@font-size-large);
				.text-underline;
				> span:first-of-type {
					.pl(0); //ovverule base icon-btn-text-position();
				}

				&:hover,
				&:focus {
					color: @link-hover-color;
					.text-underline;
				}
			}

			ul {
				.list-overview-style(
					@list-overview-style: list;
					@list-overview-type: sublist;
				);
				.pl(0);
				.pt(@grid-gutter-width/4) !important;
				.pb(@grid-gutter-width/2) !important;
				> li {
					> a {
						color: @color1-base;
						&:hover,
						&:focus {
							color: @link-color;
							.text-underline;
						}
					}
				}
				ul {
					.list-overview-style(
						@list-overview-style: list;
						@list-overview-type:  global;
					);
					.pt(@grid-gutter-width/2);
					.pl(@icon-width + 10);
					> li {
						> a {
							color: @color1-dark;
						}
					}
				}
			}

		}

	}

	& when (@list-overview-type = subitems) {
		> li {
			.icon-alt-variant(
				@icon-symbol: "";
			);
			&:not(:last-child) {
				.mb(@spacing-paragraph * 2);
			}

			> a {
				//-- styling hoofd items
				.title;
				.text-title;
				.text-underline;
				> span:first-of-type {
					.pl(0); //ovverule base icon-btn-text-position();
				}

				&:hover,
				&:focus {
					color: @link-hover-color;
					.text-underline;
				}
			}
			> ul {
				.pl(0);
				//styling subnavs
				/*
				ul {
					> li {
						.icon-alt-variant(
							@icon-symbol: @icon-base;
							@icon-position;
							@use-custom-icon: @use-custom-list-icon;
						);
						.icon-size-variant(
							@icon-size: @icon-size-base;
							@icon-position: @icon-position;
							@font-size-style: icon;
						);
						.icon-color-variant(
							@icon-color: @az-base-color;
							@icon-position;
						);
						> a {
							.icon-btn-text-position(
								@text-padding: @icon-list-position-horizontal + @list-text-padding;
								@icon-width: 7;
								@icon-position;
							);
						}
						ul {
							.pl(@icon-list-position-horizontal + @list-text-padding + 7);
						}
					}
				}
				*/
			}
		}

	}

	//## list-overview-style =  grid
	& when (@list-overview-type = link) {
		> li {
			.text-center;
		}
	}

}

//== Seperator mixin
//
//## list seperator mixin
.list-seperator(
	@list-border-color: @hr-border;
	@list-border-width: 1;
	@list-border-style: dotted;
	@seperator-spacing: @line-height-computed;
) {
	> li {
		> a {
			.pb(@seperator-spacing);
		}
		+ li {
			border-top: (@list-border-width * 1px) @list-border-style @list-border-color;
			> a {
				.pv(@seperator-spacing);
			}
		}
	}
}

.list-seperator-variant(
	@seperator-color-variant;
) {
	> li {
		+ li {
			border-top-color: @seperator-color-variant;
		}
	}
}
