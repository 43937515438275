// Mixins Font
// --------------------------------------------------
//## font size aanpassing mixin (later berekening via rems)
.font-size(
	@font-size-base;
	@font-use-mediaqueries: @font-use-mediaqueries;
	@fontsize-style: global;
) {
	& when not (@font-use-mediaqueries) {
		.check-unit(
			@property: font-size;
			@unit: @font-size-base;
		);
		& when (@fontsize-style = icon) {
			line-height: inherit;
		}
		& when not (@fontsize-style = icon) {
			& when not (@fontsize-style = onlyfontsize) and not (@fontsize-style = heading) {
				.check-unit(
					@property: line-height;
					@unit: floor((@font-size-base  * @line-height-base));
				);
			}
			& when (@fontsize-style = heading) {
				line-height: 1.1;
			}
		}
		
	}
	& when (@font-use-mediaqueries) {
		.check-unit(
			@property: font-size;
			@unit: @font-size-base;
		);

		& when (@fontsize-style = global) {
			.check-unit(
				@property: line-height;
				@unit: floor(((@font-size-base) * @line-height-base));
			);

			@media (max-width: @screen-tinyphone) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base - 2;
				);
				.check-unit(
					@property: line-height;
					@unit: floor(((@font-size-base - 2) * @line-height-base));
				);

			}
			@media (min-width: @screen-tinyphone) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base;
				);
			}
			@media (min-width: @screen-phone) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base;
				);
			}
			@media (min-width: @screen-tablet) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base;
				);
			}
			@media (min-width: @screen-desktop) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base;
				);
			}
			@media (min-width: @screen-widescreen) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base + 2;
				);
				.check-unit(
					@property: line-height;
					@unit: floor( ((@font-size-base + 2) * @line-height-base) );
				);
			}
		}

		& when (@fontsize-style = heading) {
			@media (max-width: @screen-tinyphone) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base - 6;
				);
				.check-unit(
					@property: line-height;
					@unit: floor( ((@font-size-base - 6) * @headings-line-height ));
				);
			}
			@media (min-width: @screen-tinyphone) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base - 2;
				);
				.check-unit(
					@property: line-height;
					@unit: floor( ((@font-size-base - 2) * @headings-line-height) );
				);
			}
			@media (min-width: @screen-phone) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base;
				);
			}
			@media (min-width: @screen-tablet) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base;
				);
			}
			@media (min-width: @screen-desktop) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base;
				);
			}
			@media (min-width: @screen-widescreen) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base + 3;
				);
				.check-unit(
					@property: line-height;
					@unit: floor( ((@font-size-base + 3) * @headings-line-height) );
				);


			}
		}
		& when (@fontsize-style = navigation) {
			.check-unit(
				@property: font-size;
				@unit: @font-size-base;
			);
			@media (min-width: @screen-tablet) and (max-width: @screen-tablet-max) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base - 3;
				);
				.check-unit(
					@property: line-height;
					@unit: floor( ((@font-size-base - 3) * @line-height-base) );
				);
			}
		}
		& when (@fontsize-style = home-spotlight) {
			.check-unit(
				@property: font-size;
				@unit: @font-size-base - 2;
			);
			padding: @padding-base-vertical @padding-base-horizontal;
			@media (min-width: @grid-header-breakpoint) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base;
				);
				.pv(@padding-large-vertical);
				.ph(@padding-large-horizontal);
				line-height: @line-height-large;
			}
		}
		& when (@fontsize-style = calendar) {
			@media (min-width: @grid-float-breakpoint) and (max-width: @screen-desktop-max) {
				.check-unit(
					@property: font-size;
					@unit: @font-size-base - 2;
				);
				//.square(20);
				.check-unit(
					@property: line-height;
					@unit: 20;
				);
			}
			.check-unit(
				@property: font-size;
				@unit: @font-size-base;
			);
			//.square(30);
			.pv(5);
			.check-unit(
				@property: line-height;
				@unit: 30;
			);
		}
	}
}

//## font alternate mixin
.font-alt(@fontname: @font-family-base) {
	font-family: @fontname;
}

//== Word break Mixin
//
//## word break used on email
.word-break() {
	-ms-word-break: break-all;

	/* Be VERY careful with this, breaks normal words wh_erever */
	word-break: break-all;

	/* Non standard for webkit */
	word-break: break-word;

	-webkit-hyphens: auto;
	   -moz-hyphens: auto;
			hyphens: auto;

	white-space: normal;
}

// .font-size(@fontsize: @font-size-base; @use-mediaqueries: false) {
//  @base-fontsize: @fontsize * 1px;
//  & when (@use-mediaqueries = false) {
//      font-size: @base-fontsize;
//  }

//  & when (@use-mediaqueries = true) {
//      @media (max-width: @screen-tinyphone) {
//          font-size: @base-fontsize - 4;
//      }
//      @media (min-width: @screen-tinyphone) {
//          font-size: @base-fontsize - 2;
//      }
//      @media (min-width: @screen-phone) {
//          font-size: @base-fontsize - 2;
//      }
//      @media (min-width: @screen-tablet) {
//          font-size: @base-fontsize ;
//      }
//      @media (min-width: @screen-desktop) {
//          font-size: @base-fontsize ;
//      }
//      @media (min-width: @screen-widescreen) {
//          font-size: @base-fontsize + 2;
//      }
//  }
// }
