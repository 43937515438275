/* ==========================================================================
 INFO ::  skin used to copy with grunt to be used for structual changes
 move file to destination :: lcp/skin
========================================================================== */

/* ==========================================================================
Variables overrules voor portal sites / dienst layout unlock this when be coppied from grunt task
========================================================================== */
@import "lcp/variables/variables-@{portal-name}";
@import "lcp/variables/variables-print";
/*========================================================================== */

// @media print {

	@page {
		margin: 1.5cm;
	}

	@page :left {
		margin: 0.5cm;
	}

	@page :right {
		margin: 0.5cm;
	}

	h1, .h1 {
		.font-size(24pt) !important;
	}
	h2, .h2 {
		.font-size(22pt) !important;
	}
	h3, .h3 {
		.font-size(20pt) !important;
	}
	h4, .h4 {
		.font-size(18pt) !important;
	}
	h5, .h5 {
		.font-size(16pt) !important;
	}
	h6, .h6 {
		.font-size(14pt) !important;
	}

	body {
		line-height: 1.2;
		.font-size(12pt) !important;
	}

	blockquote, ul {
		.m(0);
	}

	img,
	.streetmap {
		 -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
		filter: grayscale(100%);
		max-width: 350px;
	}

	.page-container-wrapper {
		width: 100%; margin: 0; float: none;
		.ph(@grid-gutter-width/2);
	}

	.container,
	.container-wrapper {
		width: 100% !important;
		.ph(0) !important;
	}

	.list-media {
		> li {
			display: inline-block;
			+ li {
				.ml(@padding-base-horizontal);
			}
		}
		img {
			max-width: 150px;
		}
	}

	[class^="list-"], [class*=" list"], [class*="list"] {
		.mt(0) !important;
	}

	[data-mh] {
		height: auto !important;
	}

	.masonry-grid {
		ul {
			li {
				overflow: hidden;
				position: relative !important;
				left: auto !important;
				top: auto !important;
			}
		}
	}

	.partial-container {
		.list {
			li {
				overflow: hidden;
				float: none;
			}
		}
	}


	.partial-content { height: auto !important}
	div > .photo,
	.media-link > .photo,
	a > .photo {
		max-width: 300px !important;
	}

	.partial-container {
		[class^="has-"], [class*=" has-"] {
			.one-media {
				.pull-left;
				.mr(@padding-base-horizontal) !important;
				.ml(0) !important;
				.mb(@padding-base-vertical);
				img {
					max-width: 150px;
				}
			}
			.partial-block-date {
				left: auto !important;
				right: 0 !important;
			}
		}
		.list {
			.list-unstyled();
		}
	}
	.side-bar {
		.mt(@line-height-computed/2);
	}

	.box-contact {
		page-break-inside: avoid;
		//  page-break-before: always;
	}

	.header,
	.header-banner,
	.skip,
	.toppos,
	.breadcrumb-wrapper,
	#total-items,
	#lcpbox_container,
	.lcp-bo-edit-box,
	.inplace-editing-toolbar,
	.footer,
	.header-filters,
	.filter-cloud,
	[class^="btn-"],
	[class*=" btn-"],
	nav,
	iframe,
	.sr-only,
	.theme-detail-wrapper,
	.ol-overlaycontainer-stopevent,
	.contact-popular > .heading,
	.bxslider-container {
		.hidden-print;
	}

	.box {
		&:not(.box-contact) {
			.hidden-print;
		}
	}


	p a[href^="http://"]:after, a[href^="https://"]:after {
		content: " (" attr(href) ")";
		word-wrap: break-word;
		font-size: 80%;
	}

	.contact-info {
		dd {
			.ml(0);
			.pl(0);
			.mb(@line-height-computed/2);
		}
	}

	/*
	.txt-divider,
	.txt-afspraak {
		&:after {
			content: "\00a0";
		}
	}

	.hour-part {
		> span {
			+ span {
				&:before {
					content:"\00a0";
				}
			}
		}
	}
	*/



// }



// Print utilities
//
// Media queries are placed on the inside to be mixin-friendly.

// Note: Deprecated .visible-print as of v3.2.0
.visible-print {
  .responsive-visibility();

  @media print {
	.responsive-visibility();
  }
}
.visible-print-block {
	display: block !important;

	@media print {
		display: block !important;
	}
}
.visible-print-inline {
	display: inline !important;

	@media print {
		display: inline !important;
	}
}
.visible-print-inline-block {
	display: inline-block !important;

	@media print {
		display: inline-block !important;
	}
}

.hidden-print {
	.responsive-invisibility();
	@media print {
		.responsive-invisibility();
	}
}


.lcp-toolbar{
	display: none !important
}
