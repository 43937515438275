/****************************
* CORE :: TYPOGRAPHY *
****************************/

//## Body text
p {
	.mt(0);
	.mb(0);
	&:not(:last-child) {
		.mb((@line-height-computed / 2));
	}
}

// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
	.font-size(floor((100% * @font-size-small / @font-size-base)));
}

mark,
.mark {
	background-color: @state-warning-bg;
	padding: .2em;
}



// Misc
// -------------------------

// Abbreviations and acronyms
abbr[title],
// Add data-* attribute to help out our tooltip plugin, per https://github.com/twbs/bootstrap/issues/5257
abbr[data-original-title] {
	cursor: help;
	border-bottom: 1px dotted @abbr-border-color;
}
.initialism {
	font-size: 90%;
	.text-uppercase();
}

// Blockquotes
blockquote {
	padding: (@line-height-computed / 2) @line-height-computed;
	margin: 0 0 @line-height-computed;
	font-size: @blockquote-font-size;
	border-left: 5px solid @blockquote-border-color;

	p,
	ul,
	ol {
		&:last-child {
			margin-bottom: 0;
		}
	}

	// Note: Deprecated small and .small as of v3.1.0
	// Context: https://github.com/twbs/bootstrap/issues/11660
	footer,
	small,
	.small {
		display: block;
		font-size: 80%; // back to default font-size
		line-height: @line-height-base;
		color: @blockquote-small-color;

		&:before {
			content: '\2014 \00A0'; // em dash, nbsp
		}
	}
}

// Opposite alignment of blockquote
//
// Heads up: `blockquote.pull-right` has been deprecated as of v3.1.0.
.blockquote-reverse,
blockquote.pull-right {
	padding-right: 15px;
	padding-left: 0;
	border-right: 5px solid @blockquote-border-color;
	border-left: 0;
	text-align: right;

	// Account for citation
	footer,
	small,
	.small {
		&:before { content: ''; }
		&:after {
			content: '\00A0 \2014'; // nbsp, em dash
		}
	}
}

// Addresses
address {
	margin-bottom: @line-height-computed;
	font-style: normal;
	line-height: @line-height-base;
}
