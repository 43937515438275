// Icon Mixins
// --------------------------------------------------
//== Icon variants Mixins
//
//## icon base declaration
.icon-base() {
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// .transition(@transition-base);
	display: inline-block;
}

//## font icon variant
.icon-variant(
	@icon-symbol;
	@use-custom-icon: false;
) {
	& when not (@use-custom-icon) {
		.font-alt(@icon-font-name);
	}
	& when (@use-custom-icon) {
		.font-alt(@icon-font-custom-name);
	}
	content: @icon-symbol;
}

//## icon declaratie
.icon(
	@icon-symbol;
	@icon-position: @icon-position;
	@use-custom-icon: false;
) {
	& when (@icon-position = left) {
		&:before {
			.icon-base();
			.icon-variant(
				@icon-symbol;
				@use-custom-icon;
			);
		}
	}
	& when (@icon-position = right) {
		&:after {
			.icon-base();
			.icon-variant(
				@icon-symbol;
				@use-custom-icon;
			);
		}
	}
}


.icon-alt-variant(
	@icon-symbol;
	@icon-position: @icon-position;
	@use-custom-icon: false;
) {
	& when (@icon-position = left) {
		&:before {
			.icon-variant(
				@icon-symbol;
				@use-custom-icon;
			);
		}
	}
	& when (@icon-position = right) {
		&:after {
			.icon-variant(
				@icon-symbol;
				@use-custom-icon;
			);
		}
	}
}

.icon-cbo-variant(
	@icon-symbol;
	@icon-position: @icon-position;
) {
	& when (@icon-position = left) {
		&:before {
			.font-alt(@icon-font-cbo-name);
			content: @icon-symbol;
		}
	}
	& when (@icon-position = right) {
		&:after {
			.font-alt(@icon-font-cbo-name);
			content: @icon-symbol;
		}
	}
}

//## icon variant op buttons (a tags)
.icon-btn-variant(
	@icon-btn;
	@icon-position: @icon-position;
	@btn-text: @btn-text;
	@text-padding: @padding-xs-horizontal;
	@icon-width: @icon-width;
	@use-custom-icon: false;
	@icon-btn-position-horizontal: @icon-btn-position-horizontal;
	@icon-btn-position-vertical: @icon-btn-position-vertical;
) {

	//debug
	// &:after {
	// 	content: "@{text-padding}";
	// }

	.icon(
		@icon-symbol: @icon-btn;
		@icon-position: @icon-position;
		@use-custom-icon: @use-custom-icon;
	);

	.icon-btn-text(
		@btn-text: @btn-text;
		@icon-position: @icon-position;
		@text-padding: @text-padding;
		@icon-width: @icon-width;
		@icon-position-horizontal: @icon-btn-position-horizontal;
		@icon-position-vertical: @icon-btn-position-vertical;
	);
}

//icon inline flexed
.icon-inline(
	@icon-inline;
	@icon-position;
	@inline-gutter;
	@use-custom-icon: false;
) {
	.icon(
		@icon-symbol: @icon-inline;
		@icon-position: @icon-position;
		@use-custom-icon: @use-custom-icon;
	);

	.icon-flex-position(
		@flex-align-items: center;
	);

	.icon-flex-text-position(
		@text-padding: 0;
		@icon-width: @inline-gutter;
		@icon-position: @icon-position;
		@icon-text-align: center;
		@flex-grow: false;
	);
}

.icon-btn-text(
	@btn-text: false;
	@icon-position: @icon-position;
	@text-padding: @text-padding;
	@icon-width: @icon-width;
	@icon-position-horizontal: @icon-btn-position-horizontal;
	@icon-position-vertical: @icon-btn-position-vertical;
) {
	& when not (@btn-text) {
		> span {
			.sr-only;
		}
		&:hover,
		&:focus {
			.text-none;
		}
		// & when (@icon-position = left) {
		// 	&:before {
		// 		display: block;
		// 	}
		// }
		// & when (@icon-position = right) {
		// 	&:after {
		// 		display: block;
		// 	}
		// }
	}
	& when (@btn-text) {

		//debug
		// &:after {
		// 	content: "@{icon-position}";²
		// }

		.icon-position(
			@icon-position-horizontal;
			@icon-position-vertical;
			@icon-position;
		);
		.icon-btn-text-position(
			@text-padding;
			@icon-width;
			@icon-position;
		);
	}
}

.icon-text-position(
	@text-padding;
	@icon-width;
	@icon-position;
) {
	display: block;
	& when (@icon-position = left) {
		.pl(@icon-width + @text-padding);
	}
	& when (@icon-position = right) {
		.pr(@icon-width + @text-padding);
	}
}

.icon-btn-text-position(
	@text-padding;
	@icon-width;
	@icon-position;
	@is-relative: false;
) {
	& when not(@is-relative){
		> span {
			// &:first-of-type {
			&:only-child {
				display: block;
			}
		}

		& when (@icon-position = left) {
			//first of type wanneeer je met 2 spans zou zijn bv list-file
			> span:first-of-type {
				.pl(@icon-width + @text-padding);
			}
		}
		& when (@icon-position = right) {
			> span:first-of-type {
				.pr(@icon-width + @text-padding);
			}
		}
	}

	& when (@is-relative){
		& when (@icon-position = left) {
			//first of type wanneeer je met 2 spans zou zijn bv list-file
			> span:first-of-type {
				.pl(@text-padding);
			}
		}
		& when (@icon-position = right) {
			> span:first-of-type {
				.pr(@text-padding);
			}
		}
	}
}

.icon-btn-square(
	@icon-size: @icon-btn-size;
	@icon-square: @icon-square-size;
	@icon-position: @icon-position;
) {
	.p(0) !important;
	.square(@icon-square);
	.font-size(@icon-size);
	.check-unit(
		@property: line-height;
		@unit: @icon-square;
	);
	.text-center;
	.no-border;
	display: inline-block;
	> span {
		.sr-only;
	}
	&:hover,
	&:focus {
		.text-none;
	}
	// & when (@icon-position = left) {
	// 	&:before {
	// 		.font-size(@icon-size);
	// 	}
	// }
	// & when (@icon-position = right) {
	// 	&:after {
	// 		.font-size(@icon-size);
	// 	}
	// }
}

.icon-align(
	@icon-align;
	@icon-position;
	@icon-width;
) {
	& when (@icon-position = left) {
		&:before {
			& when (@icon-align = center) {
				.align-vertical(
					@position: absolute;
					@position-v: center;
				);
				.text-center;
			}
			& when (@icon-align = hcenter) {
				.text-center;
				// .align-horizontal(
				// 	@position: absolute;
				// 	@position-h: center;
				// );
			}
			& when (@icon-align = vcenter) {
				.align-vertical(
					@position: absolute;
					@position-v: center;
				);
			}
			.check-unit(
				@property: width;
				@unit: @icon-width;
			);
		}
	}
	& when (@icon-position = right) {
		&:after {
			& when (@icon-align = center) {
				.align-vertical(
					@position: absolute;
					@position-v: center;
				);
				.text-center;
			}
			& when (@icon-align = hcenter) {
				.text-center;
				// .align-horizontal(
				// 	@position: absolute;
				// 	@position-h: center;
				// );
			}
			& when (@icon-align = vcenter) {
				.align-vertical(
					@position: absolute;
					@position-v: center;
				);
			}
			.check-unit(
				@property: width;
				@unit: @icon-width;
			);

		}
	}
}

//absolut positioning icon btn
.icon-position(
	@icon-position-horizontal;
	@icon-position-vertical;
	@icon-position: @icon-position;
	@position-vertical: top;
) {
	position: relative;
	& when (@icon-position = left) {
		&:before {
			position: absolute;
			.check-unit(
				@property: @position-vertical;
				@unit: @icon-position-vertical;
			);
			.check-unit(
				@property: left;
				@unit: @icon-position-horizontal;
			);
			// & when not (@icon-position-horizontal = 0) {}
		}
	}

	& when (@icon-position = right) {
		&:after {
			position: absolute;
			.check-unit(
				@property: @position-vertical;
				@unit: @icon-position-vertical;
			);

			.check-unit(
				@property: right;
				@unit: @icon-position-horizontal;
			);
		}
	}
}

.icon-position-reset(
	@icon-position: @icon-position;
) {
	position: relative;
	& when (@icon-position = left) {
		&:before {
			position: static;
			left: auto;
			top: auto;
		}
	}
	& when (@icon-position = right) {
		&:after {
			position: static;
			right: auto;
			top: auto;
		}
	}
}

//-- Stylng icon size and color --//
.icon-style(
	@icon-color;
	@icon-size;
	@icon-position: @icon-position;
	@fontsize-style: icon;
) {
	& when (@icon-position = left) {
		&:before {
			.font-size(
				@font-size-base: @icon-size;
				@font-use-mediaqueries: false;
				@fontsize-style: @fontsize-style;
			);
			color: @icon-color;
		}

	}
	& when (@icon-position = right) {
		&:after {
			.font-size(
				@font-size-base: @icon-size;
				@font-use-mediaqueries: false;
				@fontsize-style: icon;
			);
			color: @icon-color;
		}
	}
}

//-- Styling icon size --//
.icon-size-variant(
	@icon-size;
	@icon-position: @icon-position;
	@font-size-style: icon;
) {
	& when (@icon-position = both) {
		&:before,
		&:after {
			.font-size(
				@font-size-base: @icon-size;
				@font-use-mediaqueries: false;
				@fontsize-style: @font-size-style;
			);
		}
	}
	& when (@icon-position = left) {
		&:before {
			.font-size(
				@font-size-base: @icon-size;
				@font-use-mediaqueries: false;
				@fontsize-style: @font-size-style;
			);
		}
	}
	& when (@icon-position = right) {
		&:after {
			.font-size(
				@font-size-base: @icon-size;
				@font-use-mediaqueries: false;
				@fontsize-style: icon;
			);
		}
	}
}

//-- Styling icon color --//
.icon-color-variant(
	@icon-color;
	@icon-position: @icon-position;
) {
	& when (@icon-position = both) {
		&:before,
		&:after {
			color: @icon-color;
		}
	}
	& when (@icon-position = left) {
		&:before {
			color: @icon-color;
		}
	}
	& when (@icon-position = right) {
		&:after {
			color: @icon-color;
		}
	}
}

.icon-center(@icon-position) {
	& when (@icon-position = left) {
		&:before{
			position: absolute;
			.l(50%);
			.t(50%);
			.translate(-50%, -50%);
		}
	}

	& when (@icon-position = right) {
		&:after{
			position: absolute;
			.l(50%);
			.t(50%);
			.translate(-50%, -50%);
		}
	}
}

.icon-flex-position(
	@flex-align-items: top;
) {
	.flex-display();
	.flex-wrap(nowrap);
	& when (@flex-align-items = top) {
		.align-items(flex-start);
	}
	& when (@flex-align-items = bottom) {
		.align-items(flex-end);
	}
	& when (@flex-align-items = center) {
		.align-items(center);
	}
	& when (@flex-align-items = baseline) {
		.align-items(baseline);
	}
}

.icon-flex-text-position(
	@text-padding: @padding-xs-horizontal;
	@icon-width: @icon-width;
	@icon-position: @icon-position;
	@icon-text-align: left;
	@flex-grow: false;
) {
	& when (@icon-position = left) {
		&:before {
			// content: "@{icon-text-align}";
			& when not (@icon-width = auto) {
				.check-unit(
					@property: min-width;
					@unit: @icon-width;
				);

				.text-align(
					@text-align: @icon-text-align;
				);
			}
			// & when (@icon-width = auto) {
				& when (@icon-text-align = left) or (@icon-text-align = center) {
					.mr(@text-padding);
				}
				& when (@icon-text-align = right) {
					.ml(@text-padding);
				}
			// }

			& when (@flex-grow) {
				margin-right: auto;
			}
		}
	}
	& when (@icon-position = right) {
		&:after {
			.check-unit(
				@property: min-width;
				@unit: @icon-width + @text-padding;
			);

			.text-align(
				@text-align: @icon-text-align;
			);
			& when (@flex-grow) {
				margin-left: auto;
			}
		}
	}

	& when (@icon-position = both) {
		&:before
		&:after {
			.check-unit(
				@property: min-width;
				@unit: @icon-width + @text-padding;
			);

		}
		&:before {
			.text-align(
				@text-align: left;
			);
			.mr(@text-padding);

		}
		&:after {
			.text-align(
				@text-align: right;
			);
			.ml(@text-padding);

		}

	}

}

.icon-text-align(
	@icon-text-align: left;
	@icon-position: @icon-position;
) {
	& when (@icon-position = left) {
		&:before {
			.text-align(
				@text-align: @icon-text-align;
			);
		}
	}
	& when (@icon-position = right) {
		&:after {
			.text-align(
				@text-align: @icon-text-align;
			);
		}
	}

}

//-- used for footer icons
/*
.make-icon-classes(
	@prefix;
	@list;
	@icon-btn-color;
	@icon-btn-size;
	@btn-text;
	@text-padding;
) {
	.iter(length(@list));

	.iter(@i) when (@i > 0) {
		.iter(@i - 1);
		@pair: extract(@list, @i);
		@key: extract(@pair, 1);
		@value: extract(@pair, 2);

		> a {
			.icon-btn-variant(
				@icon-btn;
				@icon-btn-color;
				@btn-text;
				@icon-btn-size;
				@icon-position;
				@is-hover;
				@text-padding;
			);
		}
		&.@{prefix}-@{key} {
			> a {
				.icon-alt(
					@icon-symbol: @value;
					@icon-position;
					@icon-color: @icon-btn-color;
				);
			}
		}
	}
}
*/

.icon-color{
	&:before{
		color: @icon-color;
	}
}

.icon-space{
	&:before{
		.mr(@spacing/2);
		display: inline-block;
	}
}
